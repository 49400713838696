import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e7005388"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "chat-self-signature" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("h1", null, [
    _createTextVNode(_toDisplayString(_ctx.title === "对方正在输入" ? _ctx.$t("TUIChat.对方正在输入") : _ctx.title) + " ", 1),
    (_ctx.customInfo?.bole_level)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "user-role-icon",
          alt: "见习伯主",
          title: "见习伯主",
          src: require(`@/assets/image/bole_${_ctx.customInfo.bole_level}.png`)
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.selfSignature), 1)
  ]))
}