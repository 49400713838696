
import { computed, defineComponent, reactive, toRefs } from "vue";
import Link from "../../../../../utils/link";

const group: any = defineComponent({
  name: "group",
  props: {
    isH5: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      profile: {
        groupID: "",
        name: "",
        type: "",
        avatar: "",
        introduction: "",
        notification: "",
        joinOption: "",
      },
      editConfig: {
        title: "",
        value: "",
        key: "",
        type: "",
        placeholder: "",
      },
      isEdit: false,
      type: [
        {
          icon: "https://web.sdk.qcloud.com/im/assets/images/Public.svg",
          label: "公开群（Public）",
          type: group.TUIServer.TUICore.TIM.TYPES.GRP_PUBLIC,
          detail:
            "类似 QQ 群，创建后群主可以指定群管理员，用户搜索群 ID 发起加群申请后，需要群主或管理员审批通过才能入群。详见",
          src: "产品文档",
        },
        {
          icon: "https://web.sdk.qcloud.com/im/assets/images/Meeting.svg",
          label: "临时会议群（Meeting）",
          type: group.TUIServer.TUICore.TIM.TYPES.GRP_MEETING,
          detail:
            "创建后可以随意进出，且支持查看入群前消息；适合用于音视频会议场景、在线教育场景等与实时音视频产品结合的场景。详见",
          src: "产品文档",
        },
        {
          icon: "https://web.sdk.qcloud.com/im/assets/images/Work.svg",
          label: "好友工作群（Work）",
          type: group.TUIServer.TUICore.TIM.TYPES.GRP_WORK,
          detail: "类似普通微信群，创建后仅支持已在群内的好友邀请加群，且无需被邀请方同意或群主神奇。详见",
          src: "产品文档",
        },
        {
          icon: "https://web.sdk.qcloud.com/im/assets/images/AVChatroom.svg",
          label: "直播群（AVChatroom）",
          type: group.TUIServer.TUICore.TIM.TYPES.GRP_AVCHATROOM,
          detail:
            "创建后可以随意进出，没有群成员数量上限，但不支持历史消息存储；适合与直播产品结合，用于弹幕聊天场景。详见",
          src: "产品文档",
        },
      ],
    });

    data.profile.type = data.type[0].type;
    data.profile.avatar = data.type[0].icon;

    const groupTypeDetail = computed(() => {
      return data.type.filter((item: any) => {
        return item.type === data.profile.type;
      })[0];
    });

    const selected = (item: any) => {
      if (data.profile.type !== item.type) {
        data.profile.type = item.type;
        data.profile.avatar = item.icon;
      }
    };

    const submit = (profile: any) => {
      if (data.isEdit) {
        (data.profile as any)[data.editConfig.key] = data.editConfig.value;
        return (data.isEdit = !data.isEdit);
      }
      const options: any = {
        name: profile.name,
        type: profile.type,
        groupID: profile.groupID,
        avatar: profile.avatar,
      };
      ctx.emit("submit", options);
    };

    const cancel = () => {
      if (data.isEdit) {
        return (data.isEdit = !data.isEdit);
      }
      ctx.emit("cancel");
    };

    const edit = (label: string) => {
      data.isEdit = !data.isEdit;
      data.editConfig.key = label;
      data.editConfig.value = (data.profile as any)[label];
      switch (label) {
        case "name":
          data.editConfig.title = "设置群名称";
          data.editConfig.placeholder = "请输入群名称";
          data.editConfig.type = "input";
          break;
        case "groupID":
          data.editConfig.title = "设置群ID";
          data.editConfig.placeholder = "请输入群ID";
          data.editConfig.type = "input";
          break;
        case "type":
          data.editConfig.title = "选择群类型";
          data.editConfig.type = "select";
          break;
      }
    };

    const selectedEdit = (item: any) => {
      if (data.editConfig.value !== item.type) {
        data.editConfig.value = item.type;
      }
    };

    return {
      ...toRefs(data),
      selected,
      submit,
      cancel,
      Link,
      edit,
      selectedEdit,
      groupTypeDetail,
    };
  },
});
export default group;
