
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import TUIProfileEdit from "./components/TUIProfileEdit";

const TUIProfile = defineComponent({
  name: "TUIProfile",
  components: {
    TUIProfileEdit,
  },
  setup(props: any, ctx: any) {
    const TUIServer: any = TUIProfile?.TUIServer;
    const data = reactive({
      profile: {},
      isEdit: false,
      genderLabel: {
        [TUIServer.TUICore.TIM.TYPES.GENDER_MALE]: "男",
        [TUIServer.TUICore.TIM.TYPES.GENDER_FEMALE]: "女",
        [TUIServer.TUICore.TIM.TYPES.GENDER_UNKNOWN]: "不显示",
      },
      env: TUIServer.TUICore.TUIEnv,
    });

    TUIProfileEdit.TUIServer = TUIServer;

    TUIServer.bind(data);

    const VuexStore = ((window as any)?.TUIKitTUICore?.isOfficial && useStore && useStore()) || {};

    const submit = async (profile: any) => {
      const options: any = {
        nick: profile.nick,
        avatar: profile.avatar,
        gender: profile.gender || TUIServer.TUICore.TIM.TYPES.GENDER_UNKNOWN,
        selfSignature: profile.selfSignature,
        birthday: profile.birthday,
      };
      if (TUIServer.TUICore.getStore().TUIProfile.profile.nick !== profile.nick) {
        (window as any)?.TUIKitTUICore?.isOfficial &&
          VuexStore?.commit &&
          VuexStore?.commit("handleTask", 2);
      }
      try {
        await TUIServer.updateMyProfile(options);
      } catch (error) {
        console.log(error);
      }
      if (!data.env.isH5) {
        cancel();
      }
    };

    const cancel = () => {
      if (data.env.isH5) {
        data.isEdit = false;
      } else {
        changeStatus();
      }
    };

    const changeStatus = () => {
      ctx.emit("changeStatus");
    };

    const toggleEdit = () => {
      if (data.env.isH5) {
        data.isEdit = true;
      }
    };
    return {
      ...toRefs(data),
      submit,
      cancel,
      changeStatus,
      toggleEdit,
    };
  },
});
export default TUIProfile;
