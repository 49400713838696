
import {
  computed,
  defineComponent,
  getCurrentInstance,
  inject,
  onBeforeMount,
  onMounted,
  reactive,
  toRaw,
  toRefs,
} from "vue";
import { TUINotification } from "@/TUIKit/TUIPlugin";
import Drag from "@/TUIKit/TUIComponents/components/drag";
import { TUICore } from "@/TUIKit";
import { useStore } from "vuex";
import { API_RESULT_CODE, CUSTOM_MESSAGE_TYPE } from "@/config/types/common";
import { _cookie } from "@/utils/constant";
import { ElMessage } from "element-plus";
import { useRoute } from "vue-router";
import { JSONToObject } from "@/TUIKit/TUIComponents/container/TUIChat/utils/utils";
import TabBar from "@/components/TabBar.vue";
import { handleErrorPrompts } from "@/TUIKit/TUIComponents/container/utils";
import { getApplyInfoById } from "@/api/company";
import { getInterchoiceInfo, getJobInfo } from "@/api/common";
import { getWage } from "@/utils/utils";
import { CompanyAudit, GreetingData, UserType } from "@/config/types/user";
import { getImUserInfo } from "@/api/user";
import { applyInfoViaApp } from "@/api/resume";
import { VueCookies } from "vue-cookies";

const EXPIRETIME = 604800;

export default defineComponent({
  name: "Home",
  components: {
    Drag,
    TabBar,
  },
  setup() {
    const route = useRoute();
    const instance = getCurrentInstance();
    //const locale = useI18nLocale();
    const TUIKit: any = instance?.appContext.config.globalProperties.$TUIKit;
    const store = useStore && useStore();
    const taskList = computed(() => store.state.taskList);
    const tabbar = (toRaw(route).query.value as any)?.tabbar;
    const hideTabbar = tabbar === "0";
    const version: string = TUICore.instance.TIM.VERSION;
    const displayOnlineStatus = computed(() => JSON.parse(store.state.displayOnlineStatus));
    const isMsgNeedReadReceipt = computed(() => JSON.parse(store.state.isMsgNeedReadReceipt));
    const allowNotification = computed(() => JSON.parse(store.state.allowNotification));
    const siteInfo = computed(() => store.state.siteInfo);
    const $cookies = inject<VueCookies>("$cookies");
    const data = reactive({
      userInfo: TUICore.instance.getStore().TUIProfile.profile,
      env: TUIKit.TUIEnv,
      currentConversationID: "",
      showText: TUICore.instance.isOfficial,
      menuStatus: true,
      showCall: false,
      showCallMini: false,
      conversationLen: 0,
      greetings: [],
    });

    const host = computed(() => {
      const host_config = siteInfo.value.host_config;
      if (host_config) {
        const config = JSON.parse(host_config);
        const envConfig = data.env.isH5 ? config?.mobile : config?.pc;
        return {
          official_host: envConfig?.official_host || process.env.VUE_APP_HOST,
          job_host: envConfig?.job_host || process.env.VUE_APP_HOST,
          chat_host: config?.mobile?.chat_host || process.env.VUE_APP_PUBLIC_PATH,
          c_host: config?.mobile?.c_host || process.env.VUE_APP_C_HOST,
        };
      }
      return {
        official_host: process.env.VUE_APP_HOST,
        job_host: process.env.VUE_APP_HOST,
        chat_host: process.env.VUE_APP_PUBLIC_PATH,
        c_host: process.env.VUE_APP_C_HOST,
      };
    });

    onBeforeMount(async () => {
      const token = $cookies?.get(_cookie.TOKEN);
      if (token) {
        // 用户信息
        const res = await getImUserInfo({}, token);
        const { code, data: resData } = res;
        if (
          code === API_RESULT_CODE.SUCCESS &&
          (resData.user_type === UserType.PERSONAL ||
            (resData.user_type === UserType.SCHOOL && resData.school_info?.school_user_status === 2) ||
            resData.company_verification_status === CompanyAudit.AUDIT_SUCCESS)
        ) {
          data.greetings = resData.greetings;
          login(resData);
        } else {
          ElMessage.error("您暂无使用权限，请联系管理员");
        }
      } else {
        const siteInfoStr = localStorage.getItem("SiteInfo");
        let job_host = process.env.VUE_APP_HOST;
        if (siteInfoStr) {
          const siteInfo = JSON.parse(siteInfoStr);
          const hostConfigStr = siteInfo.host_config;
          if (hostConfigStr) {
            const hostConfig = JSON.parse(hostConfigStr);
            job_host = data.env.isH5
              ? hostConfig.mobile?.job_host || process.env.VUE_APP_HOST
              : hostConfig.pc?.job_host || process.env.VUE_APP_HOST;
          }
        }
        window.location.href = `${job_host}/login?redirect=${encodeURIComponent(window.location.href)}`;
      }
    });

    const login = (data: any) => {
      const { im_id, signature } = data;
      const user = {
        userID: im_id,
        userSig: signature,
      };
      // login TUIKit
      TUIKit.login(user)
        .then((res: any) => {
          const options = {
            ...user,
            ...data,
            expire: EXPIRETIME,
          };
          store.commit("setUserInfo", options);
        })
        .catch((error: any) => {
          ElMessage.error(error.message || "IM登录失败");
          //window.location.href = `${
          //  process.env.VUE_APP_HOST
          //}/login?redirect=${encodeURIComponent(window.location.href)}`;
        });
    };

    onMounted(() => {
      const queryValue: any = toRaw(route).query.value;
      if (queryValue) {
        // 打开应聘反馈
        const conversationID = queryValue.conversationID;
        conversationID && openConversation(conversationID);
        // 创建单聊
        const imId = queryValue.imId;
        //const applyId = queryValue.applyId;
        const jobId = queryValue.jobId;
        const interchoiceId = queryValue.interchoiceId;
        //const origin = queryValue.origin;
        if (imId) {
          //if (applyId) {
          //  // 个人投递简历后发起聊天
          //  // 企业端人才管理发起聊天
          //  //origin === "c" ? sendApplyResume(imId, applyId) : createConversationB2C(imId, applyId);
          //} else
          if (jobId) {
            // 个人端
            createConversationC2B(imId, jobId);
          } else if (interchoiceId) {
            // 双选会
            createConversationSxh2C(imId, interchoiceId);
          } else {
            createConversation(imId);
          }
        } else {
          // 客服
          const action = (toRaw(route).query.value as any)?.a;
          const infoStr = localStorage.getItem("TUIKit-userInfo");
          const info = infoStr && JSONToObject(infoStr);
          action === "kefu" && createConversationKefu(store.state.userInfo.im_service || info?.im_service);
        }
      } else {
        // 默认打开第一条会话
        getConversationListAndOpen();
      }
    });

    TUICore.instance.TUIServer.TUIProfile.getMyProfile().then((res: any) => {
      data.userInfo = res.data;
    });
    const getConversationListAndOpen = async () => {
      TUICore.instance.TUIServer.TUIConversation.currentStore.loading = true;
      setTimeout(() => {
        TUICore.instance.TUIServer.TUIConversation.getConversationList().then(async (imResponse: any) => {
          const conversationList = imResponse.data.conversationList;
          data.conversationLen = conversationList.length;
          data.conversationLen > 0 && !data.env.isH5 && (await openConversation(conversationList[0].conversationID));
        });
        TUICore.instance.TUIServer.TUIConversation.currentStore.loading = false;
      }, 2000);
    };
    // 打开会话
    const openConversation = (conversationID: string) => {
      TUICore.instance.TUIServer.TUIConversation.getConversationProfile(conversationID).then((imResponse: any) => {
        console.log("imResponse:", imResponse);
        const { data } = imResponse;
        data.currentConversationID = conversationID;
        const custom = JSONToObject(data.conversation.lastMessage?.payload?.data);
        // 通知 TUIConversation 添加当前会话
        // Notify TUIConversation to toggle the current conversation
        TUICore.instance.TUIServer.TUIConversation.handleCurrentConversation({
          ...data.conversation,
          businessId: custom?.businessID,
          url: custom?.url,
        });
      });
    };
    const createConversation = (imId: string) => {
      const conversationID = `C2C${imId}`;
      TUICore.instance.TUIServer.TUIConversation.getConversationProfile(conversationID).then(
        async (imResponse: any) => {
          const { data } = imResponse;
          data.currentConversationID = conversationID;
          // 通知 TUIConversation 模块切换当前会话
          await TUICore.instance.TUIServer.TUIConversation.handleCurrentConversation(data.conversation);
        }
      );
    };
    // 从投递列表页发起聊天
    const createConversationB2C = (imId: string, applyId: string) => {
      const conversationID = `C2C${imId}`;
      TUICore.instance.TUIServer.TUIConversation.getConversationProfile(conversationID).then(
        async (imResponse: any) => {
          const { data } = imResponse;
          data.currentConversationID = conversationID;
          // 通知 TUIConversation 模块切换当前会话
          await TUICore.instance.TUIServer.TUIConversation.handleCurrentConversation(data.conversation);
          // 职位卡片
          const res: any = await getApplyInfoById(applyId);
          if (res?.code === API_RESULT_CODE.SUCCESS) {
            const { company_id, company_info, job_id, job_info } = res.data;
            const address = job_info?.district_list?.map((item: any) => item.area_cn);
            const jobInfo = {
              businessID: "job",
              company_id,
              company_logo: company_info?.show_logo,
              company_name: company_info?.show_name,
              company_nature: company_info?.nature_cn,
              job_address: address?.join(","),
              job_attribs: [job_info?.nature_cn, job_info?.experience_cn, job_info?.education_cn],
              job_id,
              job_salary: getWage(
                job_info?.min_wage,
                job_info?.max_wage,
                job_info?.wage_unit,
                job_info?.wage_unit_cn,
                job_info?.is_negotiable,
                job_info?.months
              ),
              job_title: job_info?.job_name,
              version: 4,
            };
            await TUICore.instance.TUIServer.TUIChat.sendCustomMessage({
              data: jobInfo,
            })
              .then(() => {
                // 文本
                TUICore.instance.TUIServer.TUIChat.sendTextMessage("您好，收到您的投递，可以详细聊聊吗？");
              })
              .catch((error: any) => {
                ElMessage.error(error);
              });
          }
        }
      );
    };

    // 从职位详情页发起聊天
    const createConversationC2B = (imId: string, jobId: string) => {
      const conversationID = `C2C${imId}`;
      const jobGreetingStorageKey = `Job_Greetings_${conversationID}_${jobId}`;
      TUICore.instance.TUIServer.TUIConversation.getConversationProfile(conversationID).then(
        async (imResponse: any) => {
          const { data: imData } = imResponse;
          imData.currentConversationID = conversationID;
          // 通知 TUIConversation 模块切换当前会话
          await TUICore.instance.TUIServer.TUIConversation.handleCurrentConversation(imData.conversation);
          const jobGreetingStorage = localStorage.getItem(jobGreetingStorageKey);
          if (jobGreetingStorage && JSONToObject(jobGreetingStorage).isFirst) {
            return;
          }
          // 职位卡片
          const res: any = await getJobInfo(jobId);
          if (res?.code === API_RESULT_CODE.SUCCESS) {
            const {
              district_list,
              company_id,
              company_name,
              company_info,
              job_id,
              job_name,
              nature_cn,
              experience_cn,
              education_cn,
              max_wage,
              min_wage,
              months,
              wage_unit,
              wage_unit_cn,
              is_negotiable,
            } = res.data;
            const address = district_list?.map((item: any) => item.area_cn);
            const jobInfo = {
              businessID: "job",
              company_id,
              company_logo: company_info?.show_logo,
              company_name,
              company_nature: company_info?.nature_cn,
              job_address: address?.join(","),
              job_attribs: [nature_cn, experience_cn, education_cn],
              job_id,
              job_salary: getWage(min_wage, max_wage, wage_unit, wage_unit_cn, is_negotiable, months),
              job_title: job_name,
              version: 4,
            };
            await TUICore.instance.TUIServer.TUIChat.sendCustomMessage({
              data: jobInfo,
              description: `[职位]${job_name}`,
            })
              .then(async () => {
                // 文本
                const greetings: GreetingData[] = data.greetings.filter(
                  (item: any) => item.greeting_type === "job_share"
                );
                const greeting =
                  greetings?.[0]?.content || "您好，我对贵公司在招的这个职位很感兴趣，期待能和您语音或者视频沟通。";
                const greetingMessage = {
                  data: {
                    businessID: CUSTOM_MESSAGE_TYPE.GREETING,
                    company_show_msg: greeting,
                    personal_show_msg: greeting,
                  },
                  description: greeting,
                };
                await TUICore.instance.TUIServer.TUIChat.sendCustomMessage(greetingMessage).catch((error: any) => {
                  ElMessage.error(error.message);
                });
              })
              .catch((error: any) => {
                ElMessage.error(error.message);
              });
            localStorage.setItem(jobGreetingStorageKey, JSON.stringify({ isFirst: true }));
          }
        }
      );
    };
    // 创建客服会话
    const createConversationKefu = (imId: string) => {
      const conversationID = `C2C${imId}`;
      TUICore.instance.TUIServer.TUIConversation.getConversationProfile(conversationID).then(
        async (imResponse: any) => {
          const { data } = imResponse;
          data.currentConversationID = conversationID;
          // 通知 TUIConversation 模块切换当前会话
          await TUICore.instance.TUIServer.TUIConversation.handleCurrentConversation(data.conversation);
        }
      );
    };
    // 从人才大厅页发起聊天
    const createConversationSxh2C = (imId: string, interchoiceId: string) => {
      const conversationID = `C2C${imId}`;
      const sxhGreetingStorageKey = `Sxh_Greetings_${conversationID}_${interchoiceId}`;
      TUICore.instance.TUIServer.TUIConversation.getConversationProfile(conversationID).then(
        async (imResponse: any) => {
          const { data } = imResponse;
          data.currentConversationID = conversationID;
          // 通知 TUIConversation 模块切换当前会话
          await TUICore.instance.TUIServer.TUIConversation.handleCurrentConversation(data.conversation);
          const sxhGreetingStorage = localStorage.getItem(sxhGreetingStorageKey);
          if (sxhGreetingStorage && JSONToObject(sxhGreetingStorage).isFirst) {
            return;
          }
          // 双选会卡片
          const res: any = await getInterchoiceInfo(interchoiceId);
          if (res?.code === API_RESULT_CODE.SUCCESS) {
            const { id, title, show_cover_img, hoding_type } = res.data;
            const url = `${process.env.VUE_APP_SXH_HOST}/detail/${
              hoding_type === 1 ? "companyList" : "introduction"
            }?interchoiceId=${id}`;
            const sxhInfo = {
              businessID: CUSTOM_MESSAGE_TYPE.SXH,
              id,
              title,
              show_cover_img,
              url,
              version: 4,
            };
            await TUICore.instance.TUIServer.TUIChat.sendCustomMessage({
              data: sxhInfo,
              description: `[双选会]${title}`,
            })
              .then(async () => {
                // 文本
                await TUICore.instance.TUIServer.TUIChat.sendTextMessage("您好，看到您的双选会报名，可以详细聊聊吗？");
              })
              .catch((error: any) => {
                ElMessage.error(error.message);
              });
            localStorage.setItem(sxhGreetingStorageKey, JSON.stringify({ isFirst: true }));
          }
        }
      );
    };
    // 邀投递成功后发送投递简历
    const sendApplyResume = (imId: string, applyId: string) => {
      const conversationID = `C2C${imId}`;
      TUICore.instance.TUIServer.TUIConversation.getConversationProfile(conversationID).then(
        async (imResponse: any) => {
          const { data } = imResponse;
          data.currentConversationID = conversationID;
          // 通知 TUIConversation 模块切换当前会话
          await TUICore.instance.TUIServer.TUIConversation.handleCurrentConversation(data.conversation);
          // 投递简历卡片
          const token = $cookies?.get(_cookie.TOKEN) || "";
          const res: any = await applyInfoViaApp(applyId, token);
          if (res?.code === API_RESULT_CODE.SUCCESS) {
            const {
              education_merge_text,
              user_merge_text,
              work_exp_merge_text,
              resume_preview_url_to_b,
              resume_preview_url_to_c,
              resume_id,
              full_name,
              company_id,
              company_name,
              job_id,
              job_name,
              apply_time,
              apply_id,
            } = res.data;
            const resumeInfo = {
              businessID: CUSTOM_MESSAGE_TYPE.RESUME,
              education_merge_text,
              user_merge_text,
              work_exp_merge_text,
              resume_preview_url_to_b,
              resume_preview_url_to_c,
              resume_id,
              full_name,
              company_id,
              company_name,
              job_id,
              job_name,
              apply_time,
              apply_id,
              version: 4,
            };
            await TUICore.instance.TUIServer.TUIChat.sendCustomMessage({
              data: {
                businessID: CUSTOM_MESSAGE_TYPE.SYSTEM_TIP,
                company_show_msg: `已受邀投递${job_name}`,
                personal_show_msg: `已受邀投递${job_name}`,
                operateType: "review_resume",
                operateText: "点击查看简历",
                operatePayload: JSON.stringify({ apply_id, resume_preview_url_to_b }),
              },
              description: `已受邀投递${job_name}`,
            })
              .then(async () => {
                await TUICore.instance.TUIServer.TUIChat.sendCustomMessage({
                  data: resumeInfo,
                  description: `${full_name}的简历`,
                })
                  .then(() => {
                    // 文本
                    const greetings: GreetingData[] = data.greetings.filter(
                      (item: any) => item.greeting_type === "resume_share"
                    );
                    const greeting = greetings?.[0]?.content || "你好，这里是我的简历，欢迎随时沟通";
                    const greetingMessage = {
                      data: {
                        businessID: CUSTOM_MESSAGE_TYPE.GREETING,
                        company_show_msg: greeting,
                        personal_show_msg: greeting,
                      },
                      description: greeting,
                    };
                    TUICore.instance.TUIServer.TUIChat.sendCustomMessage(greetingMessage).catch((error: any) => {
                      ElMessage.error(error.message);
                    });
                  })
                  .catch((error: any) => {
                    ElMessage.error(error.message);
                  });
              })
              .catch((error: any) => {
                ElMessage.error(error.message);
              });
          }
        }
      );
    };
    const handleCurrentConversation = (value: string) => {
      data.currentConversationID = value;
    };
    const setReadReceipt = (value: boolean) => {
      store.commit("setNeedReadReceipt", value);
    };
    const setDisplayOnlineStatus = (value: boolean) => {
      store.commit("setDisplayOnlineStatus", value);
    };
    const setNotification = (value: boolean) => {
      store.commit("setNotification", value);
      TUINotification.getInstance().setNotificationConfiguration({
        allowNotifications: value,
      });
    };
    const beforeCalling = (type: string, error: any) => {
      if (error) {
        handleErrorPrompts(error, type);
        data.showCall = false;
        return;
      }
      data.showCall = true;
    };
    const afterCalling = () => {
      data.showCall = false;
      data.showCallMini = false;
    };
    const onMinimized = (oldMinimizedStatus: boolean, newMinimizedStatus: boolean) => {
      data.showCall = !newMinimizedStatus;
      data.showCallMini = newMinimizedStatus;
    };

    const onMessageSentByMe = async (message: any) => {
      const TUIServer = (window as any)?.TUIKitTUICore?.TUIServer;
      TUIServer?.TUIChat?.handleMessageSentByMeToView(message);
      return;
    };
    // 返回首页
    const toHome = () => {
      window.location.href = host.value.official_host;
    };
    return {
      ...toRefs(data),
      host,
      taskList,
      version,
      siteInfo,
      handleCurrentConversation,
      setReadReceipt,
      isMsgNeedReadReceipt,
      setDisplayOnlineStatus,
      displayOnlineStatus,
      allowNotification,
      setNotification,
      beforeCalling,
      afterCalling,
      onMinimized,
      onMessageSentByMe,
      toHome,
      hideTabbar,
    };
  },
});
