import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/kefu",
    name: "Kefu",
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(window.__POWERED_BY_QIANKUN__ ? "/chat" : "/"),
  routes,
});

export default router;
