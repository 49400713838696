export const _cookie = {
  TOKEN: "__token__",
};

export const _storage = {
  USER_INFO: "userInfo",
  JOB_HOST: "jobHost",
};

export const _subsite = process.env.VUE_APP_SUBSITE || "iguopin"; // 定义调用名
export const _domain = process.env.VUE_APP_DOMAIN || "iguopin.com"; // 定义 cookie 存储 domain
