import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ddcb5b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "name" }
const _hoisted_2 = {
  key: 0,
  class: "edit-h5-header"
}
const _hoisted_3 = { class: "left" }
const _hoisted_4 = { class: "input-box" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 1,
  class: "edit-h5-footer"
}
const _hoisted_7 = ["disabled"]
const _hoisted_8 = {
  key: 0,
  class: "icon icon-edit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_ctx.$t(`TUIChat.manage.群名称`)), 1),
    (_ctx.isEdit)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.isH5 ? 'edit-h5' : '']),
          ref: "dialog"
        }, [
          _createElementVNode("main", null, [
            (_ctx.isH5)
              ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
                  _createElementVNode("aside", _hoisted_3, [
                    _createElementVNode("h1", null, _toDisplayString(_ctx.$t(`TUIChat.manage.修改群聊名称`)), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t(`TUIChat.manage.修改群聊名称后，将在群内通知其他成员`)), 1)
                  ]),
                  _createElementVNode("span", {
                    class: "close",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleEdit && _ctx.toggleEdit(...args)))
                  }, _toDisplayString(_ctx.$t(`关闭`)), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              (_ctx.isEdit)
                ? (_openBlock(), _createBlock(_component_el_input, {
                    key: 0,
                    class: "input",
                    modelValue: _ctx.input,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.input) = $event)),
                    maxlength: "10",
                    placeholder: "字数限制10字",
                    "show-word-limit": "",
                    type: "text",
                    onKeyup: _withKeys(_ctx.updateProfile, ["enter"])
                  }, {
                    append: _withCtx(() => [
                      _createVNode(_component_el_button, { onClick: _ctx.updateProfile }, {
                        default: _withCtx(() => [
                          _createTextVNode("保存")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onKeyup"]))
                : _createCommentVNode("", true),
              (_ctx.isH5)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t(`TUIChat.manage.仅限中文、字母、数字和下划线，1-10个字`)), 1))
                : _createCommentVNode("", true)
            ]),
            (_ctx.isH5)
              ? (_openBlock(), _createElementBlock("footer", _hoisted_6, [
                  _createElementVNode("button", {
                    class: "btn",
                    disabled: !_ctx.input,
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updateProfile && _ctx.updateProfile(...args)))
                  }, _toDisplayString(_ctx.$t(`确认`)), 9, _hoisted_7)
                ]))
              : _createCommentVNode("", true)
          ])
        ], 2))
      : _createCommentVNode("", true),
    (!_ctx.isEdit || _ctx.isH5)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleEdit && _ctx.toggleEdit(...args)))
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.groupProfile.name), 1),
          (_ctx.isAuth)
            ? (_openBlock(), _createElementBlock("i", _hoisted_8))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}