
import { defineComponent, watchEffect, reactive, toRefs, computed } from "vue";
import MessageText from "./message-text.vue";
import MessageImage from "./message-image.vue";
import MessageVideo from "./message-video.vue";
import MessageAudio from "./message-audio.vue";
import MessageFile from "./message-file.vue";
import MessageFace from "./message-face.vue";
import MessageLocation from "./message-location.vue";
import MessageCustom from "./message-custom.vue";
import {
  handleTextMessageShowContext,
  handleImageMessageShowContext,
  handleVideoMessageShowContext,
  handleAudioMessageShowContext,
  handleFileMessageShowContext,
  handleFaceMessageShowContext,
  handleLocationMessageShowContext,
  handleMergerMessageShowContext,
  handleCustomMessageShowContext,
} from "../utils/utils";
import { Message } from "../interface";
import ImagePreviewer from "../plugin-components/imagePreviewer";
import MergerDialog from "../plugin-components/mergerDialog";

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    types: {
      type: Object,
      default: () => ({}),
    },
    imId: {
      type: String,
      default: () => "",
    },
  },
  components: {
    MessageText,
    MessageImage,
    MessageVideo,
    MessageAudio,
    MessageFile,
    MessageFace,
    MessageLocation,
    MessageCustom,
    ImagePreviewer,
    MergerDialog,
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      data: {} as any,
      types: {} as any,
      env: {},
      imId: "",
      dialogVisible: false,
      showImagePreview: false,
      currentImagePreview: {} as Message,
    });

    const imageList = computed(() =>
      data.data?.messageList?.filter((item: any) => {
        item.payload = item.messageBody[0].payload;
        return !item.isRevoked && item.messageBody[0].type === data.types.MSG_IMAGE;
      })
    );

    watchEffect(() => {
      data.data = props.data;
      data.types = props.types;
      data.imId = props.imId;
    });

    return {
      ...toRefs(data),
      imageList,
      handleTextMessageShowContext,
      handleImageMessageShowContext,
      handleVideoMessageShowContext,
      handleAudioMessageShowContext,
      handleFileMessageShowContext,
      handleFaceMessageShowContext,
      handleLocationMessageShowContext,
      handleMergerMessageShowContext,
      handleCustomMessageShowContext,
    };
  },
});
