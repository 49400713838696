import { resolveComponent as _resolveComponent, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4982fb89"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notification" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    (_ctx.isEdit)
      ? (_openBlock(), _createBlock(_component_el_input, {
          key: 0,
          modelValue: _ctx.input,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
          maxlength: "100",
          placeholder: "字数限制100字",
          "show-word-limit": "",
          type: "textarea",
          onKeyup: _withKeys(_ctx.updateProfile, ["enter"])
        }, null, 8, ["modelValue", "onKeyup"]))
      : (_openBlock(), _createElementBlock("section", _hoisted_2, [
          (!_ctx.groupProfile.notification)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t(`TUIChat.manage.暂无公告`)), 1))
            : (_openBlock(), _createElementBlock("article", _hoisted_4, _toDisplayString(_ctx.groupProfile.notification), 1))
        ])),
    (_ctx.isAuth)
      ? (_openBlock(), _createElementBlock("footer", _hoisted_5, [
          (_ctx.isEdit)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateProfile && _ctx.updateProfile(...args)))
              }, _toDisplayString(_ctx.$t(`TUIChat.manage.发布`)), 1))
            : (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isEdit = !_ctx.isEdit))
              }, _toDisplayString(_ctx.$t(`TUIChat.manage.编辑`)), 1))
        ]))
      : _createCommentVNode("", true)
  ]))
}