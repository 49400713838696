
import { defineComponent, watchEffect, reactive, toRefs, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore && useStore();
    const siteInfo = computed(() => store.state.siteInfo);
    const data = reactive({
      message: {},
    });

    const host = computed(() => {
      const host_config = siteInfo.value.host_config;
      if (host_config) {
        const config = JSON.parse(host_config);
        const envConfig = props.isH5 ? config?.mobile : config?.pc;
        return {
          b_host: envConfig?.b_host || process.env.VUE_APP_B_HOST,
        };
      }
      return {
        b_host: process.env.VUE_APP_B_HOST,
      };
    });

    watchEffect(() => {
      data.message = props.data;
    });
    const handleOperate = () => {
      const { operateType, operatePayload } = data.message as any;
      switch (operateType) {
        case "review_resume":
          window.open(operatePayload.resume_preview_url_to_b.replace(process.env.VUE_APP_B_HOST, host.value.b_host));
          break;
      }
    };

    return {
      handleOperate,
      ...toRefs(data),
    };
  },
});
