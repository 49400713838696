export interface UserInfo {
  id: string; // ID
  user_type?: UserType;
  user_name: string; // 用户名
  mobile: string; // 手机号
  area_code: string; // 手机区号
  email: string; // 邮箱
  verification_status: REAL_NAME_AUTH;
  gender?: string; // 性别
  full_name: string; // 真实姓名（实名认证姓名）
  avatars?: string; // 头像
  show_avatars?: string; // 头像url
  job_status?: string; // 求职状态编码
  job_status_name?: string; // 求职状态中文
  company_id?: string; // 企业用户信息-企业id
  company_name?: string; // 企业用户信息-企业名称
  logo?: string; // 企业用户信息-企业logo
  show_logo?: string; // 企业用户信息-企业url
  company_audit?: string; // 企业资料审核状态：1-待审核，2-审核通过，3-审核未通过
  [key: string]: any;
}

export const enum UserType {
  PERSONAL = "personal",
  COMPANY = "company",
  SCHOOL = "school",
}

// 用户认证状态 实名认证状态，1-未认证，2-已认证，3-认证失败，4-待审核
export enum REAL_NAME_AUTH {
  NOT_AUTH = 1,
  AUTH_SUCCESS = 2,
  AUTH_FAIL = 3,
  WAITTING_AUTH = 4,
}

// 用户角色
export enum UserRole {
  SUPER_ADMIN = "super_admin", // 主管理员
  SENIOR_ADMIN = "senior_admin", // 高级管理员
  GENERAL_ADMIN = "general_admin", // 普通管理员
  OPS_ADMIN = "ops_admin", // 运维管理员
}

// 单位认证状态
export enum CompanyAudit {
  UN_AUDIT = 1, // 未完成认证步骤
  NOT_AUDIT = 2, // 待审核
  AUDIT_SUCCESS = 3, // 审核通过
  AUDIT_FAIL = 4, // 审核未通过
}

// 招呼语
export interface GreetingData {
  id: number;
  content: string;
  greeting_type: "job_share" | "resume_share";
  greeting_type_cn: "职位咨询" | "简历分享";
}
