import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bbb8677"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "homepage-body" }
const _hoisted_2 = { class: "homepage-body-left" }
const _hoisted_3 = { class: "homepage-title" }
const _hoisted_4 = { class: "homepage-content" }
const _hoisted_5 = { class: "homepage-body-right" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "homepage",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toHomePageDetail && _ctx.toHomePageDetail(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.isCustom.title), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.isCustom.subtitle), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("img", {
          src: _ctx.isCustom.avatars,
          class: "homepage-cover-img"
        }, null, 8, _hoisted_6)
      ])
    ])
  ]))
}