import { _cookie, _domain, _storage } from "./constant";
import { ref, readonly, watchEffect, Ref, DeepReadonly } from "vue";
import { WageUnit } from "@/config/types/common";
import VueCookies from "vue-cookies";

const Cookies: any = VueCookies;

// 对请求数据去除首尾空格
export function trimData(data: any) {
  let newData: any = null;
  if (data instanceof Array) {
    // 仅针对数组
    newData = [];
    for (let i = 0, len = data.length; i < len; i++) {
      newData.push(trimData(data[i]));
    }
  } else if (data instanceof Object && data.constructor.name === "Object") {
    // 仅针对普通对象
    newData = {};
    for (let key in data) {
      newData[key] = trimData(data[key]);
    }
  } else if (typeof data === "string") {
    // 清除开头和结尾的空格
    newData = data.replace(/^\s+|\s+$/gm, "");
  } else {
    newData = data;
  }
  return newData;
}

// 在新标签页打开链接
export function openurl(url: string) {
  //创建A标签
  var a = document.createElement("a");
  //给创建好的a标签赋值
  a.setAttribute("href", url);
  //设置css 隐藏属性
  a.setAttribute("style", "display:none");
  //设置 a标签为新窗口打开
  a.setAttribute("target", "_blank");
  //将设置好的a标签，添加到 body 内
  document.body.appendChild(a);
  //模拟点击
  a.click();
  //移除a标签
  // @ts-ignore
  a.parentNode.removeChild(a);
}
// 移除用户信息
export function resetUserInfo() {
  const domain = /stac.fun$/.test(window.location.hostname) ? "stac.fun" : "iguopin.com";
  Cookies?.remove(_cookie.TOKEN, "/", _domain || domain);
  localStorage.removeItem(_storage.USER_INFO);
}

/**
 * Handle overlapping async evaluations
 *
 * @param cancelCallback The provided callback is invoked when a re-evaluation of the computed value is triggered before the previous one finished
 */
export type AsyncComputedOnCancel = (cancelCallback: () => void) => void;

export type AsyncComputedResult<T> = [value: DeepReadonly<Ref<T>>, evaluating: DeepReadonly<Ref<boolean>>];

/**
 * Create an asynchronous computed dependency
 *
 * @param callback     The promise-returning callback which generates the computed value
 * @param defaultValue A default value, used until the first evaluation finishes
 * @returns A two-item tuple with the first item being a readonly ref to the computed value and the second item holding a boolean ref, indicating whether the async computed value is currently (re-)evaluated
 */
export default function useAsyncComputed<T>(
  callback: (onCancel: AsyncComputedOnCancel) => T | Promise<T>,
  defaultValue?: T
): DeepReadonly<Ref<T>> {
  let counter = 0;
  const current = ref(defaultValue) as Ref<T>;
  const evaluating = ref<boolean>(false);

  watchEffect(async (onInvalidate) => {
    counter++;
    const counterAtBeginning = counter;
    let hasFinished = false;

    try {
      // Defer initial setting of `evaluating` ref
      // to avoid having it as a dependency
      Promise.resolve().then(() => {
        evaluating.value = true;
      });

      const result = await callback((cancelCallback) => {
        onInvalidate(() => {
          evaluating.value = false;
          if (!hasFinished) {
            cancelCallback();
          }
        });
      });

      if (counterAtBeginning === counter) {
        current.value = result;
      }
    } finally {
      evaluating.value = false;
      hasFinished = true;
    }
  });

  return readonly(current);
}
// 薪资
export const getWage = (
  min_wage: number,
  max_wage: number,
  wage_unit: WageUnit.DAY | WageUnit.MONTH,
  wage_unit_cn: string,
  is_negotiable: boolean,
  months?: number
) => {
  let wage = "";
  if (is_negotiable) {
    wage = "面议";
  } else {
    if (wage_unit === WageUnit.DAY) {
      // 日薪
      if (min_wage === max_wage) {
        wage = `${min_wage}${wage_unit_cn}`;
      } else {
        wage = `${min_wage}~${max_wage}${wage_unit_cn}`;
      }
    } else if (min_wage && max_wage) {
      // wage_unit === WageUnit.MONTH
      // 月薪
      const minWage = (min_wage / 1000).toFixed(0);
      const maxWage = (max_wage / 1000).toFixed(0);
      if (min_wage === max_wage) {
        if (min_wage < 1000) {
          wage = `1K以下`;
        } else {
          wage = `${minWage}K`;
        }
      } else {
        if (max_wage < 1000) {
          wage = `1K以下`;
        } else if (min_wage < 1000 && max_wage >= 1000) {
          wage = `${maxWage}K以下`;
        } else {
          wage = `${minWage}~${maxWage}K`;
        }
      }
      if (months && months !== 12) {
        wage += `·${months}薪`;
      }
    }
  }
  return wage;
};
