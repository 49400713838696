
import { defineComponent, reactive, toRefs, watch, watchEffect } from "vue";

export default defineComponent({
  name: "TabBar",
  props: {
    active: {
      type: String,
      default: "chat",
    },
    host: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const data = reactive({
      host: {},
      tabBarList: [] as any,
    });

    watchEffect(() => {
      data.host = props.host;
    });

    watch(
      () => data.host,
      (val: any) => {
        data.tabBarList = [
          {
            key: "home",
            icon: "icon-home",
            href: val.official_host,
            title: "首页",
          },
          {
            key: "job",
            icon: "icon-job",
            href: `${val.job_host}/job`,
            title: "职位",
          },
          {
            key: "chat",
            icon: "icon-chat",
            href: val.chat_host,
            title: "消息",
          },
          {
            key: "user",
            icon: "icon-user",
            href: val.c_host,
            title: "我的",
          },
        ];
      }
    );

    return {
      ...toRefs(data),
    };
  },
});
