import { request } from "../utils/request";

// 获取职位详情页信息
export function getJobInfo(id: string) {
  return request({
    url: "/api/jobs/v1/info",
    method: "GET",
    params: { id },
  });
}
// 获双选会详情页信息
export function getInterchoiceInfo(id: string) {
  return request({
    url: "/api/activity/interchoice/v1/info",
    method: "GET",
    params: { id },
  });
}
// 获取分站详情 (5.0接口)
// http://apidoc.stac.fun/project/132/interface/api/10220
export function getSiteInfo(alias: string) {
  return request({
    url: "/api/base/site/v1/info",
    method: "get",
    params: { alias },
  }) as any;
}
