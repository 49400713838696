import { request, requestApp } from "../utils/request";

// 用户绑定的企业列表
export function getImUserSig() {
  return request({
    url: "/personal/user/account/v1/im-user-sig",
    method: "post",
  }) as any;
}
// 获取用户信息
export function getUserInfo() {
  return request({
    url: "/personal/user/account/v1/userinfo",
    method: "post",
  }) as any;
}
// 获取用户信息
export function getImUserInfo(data: any, token: string) {
  return requestApp({
    url: "/api",
    method: "post",
    data: {
      body: data,
      head: {
        action: 8500,
        channel: "h5",
        is_first: 0,
        language: "zh",
        os: "h5",
        screen: "720*1560",
        time: (new Date().getTime() / 1000).toFixed(0),
        token,
        type: "h5",
        user_agent: "MI CC 9e_Android 10 API 29",
        user_id: "",
        version: "5.0.0",
      },
    },
  }) as any;
}
