
import { computed, defineComponent, reactive, toRefs, watchEffect } from "vue";

export default defineComponent({
  name: "SvgIcon",
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const data = reactive({
      iconClass: "",
      className: "",
    });

    watchEffect(() => {
      data.iconClass = props.iconClass;
      data.className = props.className;
    });

    const iconName = computed(() => `#icon-${data.iconClass}`);
    const svgClass = computed(() => (data.className ? `svg-icon ${data.className}` : `svg-icon`));

    return {
      ...toRefs(data),
      iconName,
      svgClass,
    };
  },
});
