
import { defineComponent, reactive, watchEffect, toRefs, watch, ref, onMounted, inject } from "vue";
import { useI18nLocale } from "../../../../../TUIPlugin/TUIi18n";
import { onClickOutside } from "@vueuse/core";
import { Search } from "@element-plus/icons-vue";
import { _cookie } from "@/utils/constant";
import { getJobListViaApp, inviteApplyViaApp } from "@/api/resume";
import { API_RESULT_CODE, CUSTOM_MESSAGE_TYPE } from "@/config/types/common";
import { ElMessage } from "element-plus";
import { VueCookies } from "vue-cookies";

const InviteResume = defineComponent({
  type: "custom",
  components: {
    Search,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    userId: {
      type: String,
      default: () => "",
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props: any) {
    const $cookies = inject<VueCookies>("$cookies");
    const data = reactive({
      list: [],
      userId: "",
      locale: useI18nLocale(),
      jobVisible: false,
      jobId: "",
      searchKey: "",
      jobList: [],
      token: $cookies?.get(_cookie.TOKEN) || "",
      inviteDisable: false,
      searchLoading: false,
    });

    const dialog: any = ref();

    watchEffect(() => {
      data.jobVisible = props.show;
      data.userId = props.userId;
    });

    onMounted(() => {
      const token = $cookies?.get(_cookie.TOKEN);
      if (token) {
        data.token = token;
      }
      handleSearch();
    });

    const toggleShow = () => {
      data.jobVisible = !data.jobVisible;
    };

    onClickOutside(dialog, () => {
      data.jobVisible = false;
    });

    const handleSearch = async () => {
      if (data.token) {
        data.searchLoading = true;
        const res = await getJobListViaApp(data.token, data.searchKey);
        if (res?.code === API_RESULT_CODE.SUCCESS) {
          const { list = [] } = res.data;
          data.jobList = list;
        } else {
          ElMessage.error(res?.msg || "职位获取失败");
        }
        data.searchLoading = false;
      }
    };

    const handleInvite = async () => {
      if (!data.jobId) {
        ElMessage.warning("请选择邀投递的职位");
      } else {
        data.inviteDisable = true;
        const jobInfo: any = data.jobList.filter((item: any) => item.id === data.jobId)?.[0];
        const res = await inviteApplyViaApp(data.jobId, data.userId, jobInfo.job_name, data.token);
        if (res?.code === API_RESULT_CODE.SUCCESS) {
          toggleShow();
          const { invite_id, invite_show_msg, invite_user_id, job_id, job_name, receive_show_msg, receive_user_id } =
            res.data;
          const inviteMessage = {
            data: {
              businessID: CUSTOM_MESSAGE_TYPE.INVITE_APPLY,
              invite_id,
              job_id,
              job_name,
              invite_show_msg,
              receive_show_msg,
              invite_user_id,
              receive_user_id,
            },
            description: receive_show_msg,
          };
          InviteResume.TUIServer.sendCustomMessage(inviteMessage).catch((error: any) => {
            ElMessage.error(error);
          });
        } else {
          ElMessage.error(res?.msg || "发送失败");
        }
        data.inviteDisable = false;
      }
    };
    return {
      ...toRefs(data),
      toggleShow,
      handleInvite,
      handleSearch,
      dialog,
    };
  },
});
export default InviteResume;
