import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelDynamic as _vModelDynamic, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f8d001c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "edit-header" }
const _hoisted_2 = { class: "edit-header-title" }
const _hoisted_3 = { class: "edit-list" }
const _hoisted_4 = { class: "edit-list-item space-top" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "avatar-list"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 0,
  class: "icon icon-right"
}
const _hoisted_11 = { class: "edit-list-item space-top" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  key: 0,
  class: "icon icon-right"
}
const _hoisted_14 = { class: "edit-list-item" }
const _hoisted_15 = { class: "edit-list-item-content" }
const _hoisted_16 = { class: "edit-list-item space-top" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = {
  key: 0,
  class: "icon icon-right"
}
const _hoisted_19 = { class: "edit-list-item" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = {
  key: 1,
  class: "gender-list"
}
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "name" }
const _hoisted_24 = {
  key: 0,
  class: "icon icon-right"
}
const _hoisted_25 = { class: "edit-list-item" }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = {
  key: 0,
  class: "icon icon-right"
}
const _hoisted_28 = {
  key: 0,
  class: "edit-footer"
}
const _hoisted_29 = { class: "mask-main" }
const _hoisted_30 = { class: "edit-h5-header" }
const _hoisted_31 = { class: "edit-h5-main" }
const _hoisted_32 = {
  key: 0,
  class: "list"
}
const _hoisted_33 = ["onClick"]
const _hoisted_34 = ["src"]
const _hoisted_35 = {
  key: 1,
  class: "input"
}
const _hoisted_36 = ["placeholder"]
const _hoisted_37 = ["type", "placeholder"]
const _hoisted_38 = { key: 2 }
const _hoisted_39 = { class: "edit-h5-footer" }
const _hoisted_40 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["edit", [_ctx.isH5 ? 'edit-h5' : '']])
  }, [
    _createElementVNode("header", _hoisted_1, [
      (_ctx.isH5)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: "icon icon-back",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
          }))
        : _createCommentVNode("", true),
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("TUIProfile.资料设置")), 1)
    ]),
    _createElementVNode("ul", _hoisted_3, [
      _createElementVNode("li", _hoisted_4, [
        _createElementVNode("main", {
          class: "edit-list-item-content",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setProfile('avatar')))
        }, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t("TUIProfile.头像")), 1),
          (_ctx.isH5)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                _createElementVNode("img", {
                  src: 
                _ctx.profile.avatar ? _ctx.profile.avatar : 'https://image.iguopin.com/data/app/im-logo.png'
              ,
                  onerror: "this.src='https://image.iguopin.com/data/app/im-logo.png'"
                }, null, 8, _hoisted_6)
              ]))
            : (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.avatarList, (item, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "avatar-list-item",
                    key: index,
                    onClick: ($event: any) => (_ctx.chooseAvatar(item))
                  }, [
                    _createElementVNode("img", {
                      class: _normalizeClass([_ctx.profile.avatar === item.avatar && 'selected']),
                      src: item.avatar
                    }, null, 10, _hoisted_9)
                  ], 8, _hoisted_8))
                }), 128))
              ]))
        ]),
        (_ctx.isH5)
          ? (_openBlock(), _createElementBlock("i", _hoisted_10))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("li", _hoisted_11, [
        _createElementVNode("main", {
          class: "edit-list-item-content",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setProfile('nick')))
        }, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t("TUIProfile.昵称")), 1),
          (_ctx.isH5)
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.profile.nick), 1))
            : _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 1,
                type: "text",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.profile.nick) = $event))
              }, null, 512)), [
                [_vModelText, _ctx.profile.nick]
              ])
        ]),
        (_ctx.isH5)
          ? (_openBlock(), _createElementBlock("i", _hoisted_13))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("li", _hoisted_14, [
        _createElementVNode("main", _hoisted_15, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t("TUIProfile.账号")), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.profile.userID), 1)
        ])
      ]),
      _createElementVNode("li", _hoisted_16, [
        _createElementVNode("main", {
          class: "edit-list-item-content",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setProfile('selfSignature')))
        }, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t("TUIProfile.个性签名")), 1),
          (_ctx.isH5)
            ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.profile.selfSignature), 1))
            : _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 1,
                type: "text",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.profile.selfSignature) = $event))
              }, null, 512)), [
                [_vModelText, _ctx.profile.selfSignature]
              ])
        ]),
        (_ctx.isH5)
          ? (_openBlock(), _createElementBlock("i", _hoisted_18))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("li", _hoisted_19, [
        _createElementVNode("main", {
          class: "edit-list-item-content",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setProfile('gender')))
        }, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t("TUIProfile.性别")), 1),
          (_ctx.isH5)
            ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.profile.gender ? _ctx.$t(`TUIProfile.${_ctx.genderLabel[_ctx.profile.gender]}`) : ""), 1))
            : (_openBlock(), _createElementBlock("ul", _hoisted_21, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.type, (item, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "gender-list-item",
                    key: index,
                    onClick: ($event: any) => (_ctx.showChooseGender(item))
                  }, [
                    _createElementVNode("i", {
                      class: _normalizeClass(["gender", [_ctx.profile.gender === item.type && 'gender-selected']])
                    }, null, 2),
                    _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.$t(`TUIProfile.${item.label}`)), 1)
                  ], 8, _hoisted_22))
                }), 128))
              ]))
        ]),
        (_ctx.isH5)
          ? (_openBlock(), _createElementBlock("i", _hoisted_24))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("li", _hoisted_25, [
        _createElementVNode("main", {
          class: "edit-list-item-content",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setProfile('birthday')))
        }, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t("TUIProfile.出生年月")), 1),
          (_ctx.isH5)
            ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(_ctx.profile.birthday), 1))
            : (_openBlock(), _createBlock(_component_Datepicker, {
                key: 1,
                placeholder: _ctx.$t(`TUIProfile.请选择出生日期`),
                enableTimePicker: false,
                format: _ctx.format,
                previewFormat: _ctx.format,
                modelValue: _ctx.birthday,
                "onUpdate:modelValue": _ctx.showBirthday
              }, null, 8, ["placeholder", "format", "previewFormat", "modelValue", "onUpdate:modelValue"]))
        ]),
        (_ctx.isH5)
          ? (_openBlock(), _createElementBlock("i", _hoisted_27))
          : _createCommentVNode("", true)
      ])
    ]),
    (!_ctx.isH5)
      ? (_openBlock(), _createElementBlock("footer", _hoisted_28, [
          _createElementVNode("button", {
            class: "btn-default",
            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
          }, _toDisplayString(_ctx.$t("TUIProfile.取消")), 1),
          _createElementVNode("button", {
            class: "btn-submit",
            onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
          }, _toDisplayString(_ctx.$t("TUIProfile.保存")), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.setName && _ctx.isH5)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "mask",
          onClick: _cache[14] || (_cache[14] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeMask && _ctx.closeMask(...args)), ["self"]))
        }, [
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("header", _hoisted_30, [
              _createElementVNode("h1", null, _toDisplayString(_ctx.$t(`TUIProfile.${_ctx.editConfig.title}`)), 1),
              _createElementVNode("span", {
                class: "close",
                onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.closeMask && _ctx.closeMask(...args)))
              }, _toDisplayString(_ctx.$t(`关闭`)), 1)
            ]),
            _createElementVNode("main", _hoisted_31, [
              (_ctx.editConfig.type === 'select')
                ? (_openBlock(), _createElementBlock("ul", _hoisted_32, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editConfig.list, (item, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        class: "list-item",
                        key: index,
                        onClick: ($event: any) => (_ctx.choose(item))
                      }, [
                        (item?.avatar)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              class: _normalizeClass([_ctx.editConfig.value === item.avatar && 'selected']),
                              src: item.avatar
                            }, null, 10, _hoisted_34))
                          : (_openBlock(), _createElementBlock("span", {
                              key: 1,
                              class: _normalizeClass([_ctx.editConfig.value === item.type && 'selected'])
                            }, _toDisplayString(_ctx.$t(`TUIProfile.${item.label}`)), 3))
                      ], 8, _hoisted_33))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_35, [
                    (_ctx.editConfig.type === 'textarea')
                      ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                          key: 0,
                          placeholder: _ctx.editConfig.placeholder,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editConfig.value) = $event))
                        }, null, 8, _hoisted_36)), [
                          [_vModelText, _ctx.editConfig.value]
                        ])
                      : (_ctx.editConfig.type === 'date')
                        ? (_openBlock(), _createBlock(_component_Datepicker, {
                            key: 1,
                            class: "datePicker",
                            placeholder: _ctx.$t(`TUIProfile.请选择出生日期`),
                            enableTimePicker: false,
                            format: _ctx.format,
                            previewFormat: _ctx.format,
                            modelValue: _ctx.birthday,
                            "onUpdate:modelValue": _ctx.showBirthday
                          }, null, 8, ["placeholder", "format", "previewFormat", "modelValue", "onUpdate:modelValue"]))
                        : _withDirectives((_openBlock(), _createElementBlock("input", {
                            key: 2,
                            type: _ctx.editConfig.type,
                            placeholder: _ctx.$t(`TUIProfile.${_ctx.editConfig.placeholder}`),
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editConfig.value) = $event))
                          }, null, 8, _hoisted_37)), [
                            [_vModelDynamic, _ctx.editConfig.value]
                          ])
                  ])),
              (_ctx.editConfig.subText)
                ? (_openBlock(), _createElementBlock("sub", _hoisted_38, _toDisplayString(_ctx.$t(`TUIProfile.${_ctx.editConfig.subText}`)), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("footer", _hoisted_39, [
              _createElementVNode("button", {
                class: "btn btn-submit",
                disabled: !_ctx.editConfig.value,
                onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
              }, _toDisplayString(_ctx.$t(`确定`)), 9, _hoisted_40)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}