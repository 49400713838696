
import { defineComponent, watchEffect, reactive, toRefs } from "vue";
import { translateGroupSystemNotice } from "../utils/utils";

export default defineComponent({
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    types: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      messageList: [],
      types: {},
    });

    watchEffect(() => {
      data.messageList = props.data;
      data.types = props.types;
    });

    const handleApplication = (handleAction: string, message: any) => {
      const options: any = {
        handleAction,
        message,
      };
      ctx.emit("application", options);
    };

    return {
      ...toRefs(data),
      translateGroupSystemNotice,
      handleApplication,
    };
  },
});
