
import { Message } from "../interface";
import { defineComponent, reactive, toRefs, watch, watchEffect } from "vue";
import constant from "../../constant";
const MessageReference = defineComponent({
  props: {
    message: {
      type: Object,
      default: {} as Message,
    },
    referenceMessage: {
      type: Object,
      default: () => ({}),
    },
    referenceForShow: {
      type: Object,
      default: () => ({}),
    },
    face: {
      type: Array,
      default: () => [],
    },
    url: {
      type: String,
      default: "",
    },
    allMessageID: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      deafault: "",
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      message: {},
      referenceMessage: {},
      referenceForShow: {},
      allMessageID: "",
      face: [],
      url: "",
      type: "",
    });
    watchEffect(() => {
      data.message = props.message;
      data.referenceMessage = props.referenceMessage;
      data.referenceForShow = props.referenceForShow;
      data.face = props.face;
      data.url = props.url;
      data.allMessageID = props.allMessageID;
      data.type = props.type;
    });
    watch(
      () => props.referenceForShow,
      () => {
        data.referenceForShow = props.referenceForShow;
      },
      {
        deep: true,
      }
    );
    return {
      ...toRefs(data),
      constant,
    };
  },
});
export default MessageReference;
