
import { ElMessage } from "element-plus";
import { defineComponent, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { getSiteInfo } from "./api/common";
import { API_RESULT_CODE } from "./config/types/common";
import { _subsite } from "./utils/constant";
import { useCssVar } from "@vueuse/core";

export default defineComponent({
  name: "App",
  setup() {
    const store = useStore && useStore();
    const el = ref(null);
    const elColorPrimary = useCssVar("--el-color-primary", el);
    const elColorPrimaryLight3 = useCssVar("--el-color-primary-light-3", el);
    const elColorPrimaryDark2 = useCssVar("--el-color-primary-dark-2", el);
    const colorPrimary = useCssVar("--color-primary", el);
    const colorPrimaryLight5 = useCssVar("--color-primary-light-5", el);
    const colorPrimaryLight7 = useCssVar("--color-primary-light-7", el);

    onBeforeMount(async () => {
      const res = await getSiteInfo(_subsite);
      if (res?.code === API_RESULT_CODE.SUCCESS) {
        store.commit("setSiteInfo", res.data);
        const { name, color, show_icon } = res.data;
        document.title = `消息通知-${name}`;

        elColorPrimary.value = color;
        elColorPrimaryLight3.value = `${color}c9`;
        elColorPrimaryDark2.value = color;
        colorPrimary.value = color;
        colorPrimaryLight5.value = `${color}80`;
        colorPrimaryLight7.value = `${color}4d`;

        const favicon = show_icon || "";
        if (favicon) {
          const link = document.querySelector('link[rel="icon"]');
          link?.setAttribute("href", favicon);
        }
      } else {
        ElMessage.error(res?.msg || "站点信息获取失败");
      }
    });
  },
});
