
import { defineComponent, reactive, watchEffect, toRefs, watch, ref } from "vue";
import { useI18nLocale } from "../../../../../TUIPlugin/TUIi18n";
import { onClickOutside } from "@vueuse/core";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";

const Words = defineComponent({
  type: "custom",
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    isMute: {
      type: Boolean,
      default: () => false,
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props: any, ctx: any) {
    const { t } = (window as any).TUIKitTUICore.config.i18n.useI18n();
    const store = useStore && useStore();
    const data = reactive({
      list: [],
      show: false,
      isMute: false,
      locale: useI18nLocale(),
    });

    const dialog: any = ref();

    //watch(() => data.locale, (newVal:any, oldVal:any) => {
    //  data.list = list.map((item:any) => ({
    //    value: t(`Words.${item.value}`),
    //  }));
    //});

    watchEffect(() => {
      data.show = props.show;
      data.isMute = props.isMute;
    });

    const toggleShow = () => {
      data.show = !data.show;
      if (data.show) {
        data.list = store.state.userInfo.common_phrases;
        //data.list = bList.map((item: any) => ({
        //  value: t(`Words.${item.value}`),
        //}));
      }
    };

    onClickOutside(dialog, () => {
      data.show = false;
    });

    const select = (item: any, index?: number) => {
      toggleShow();
      Words.TUIServer.sendTextMessage(item).catch((error: any) => {
        ElMessage.error(error);
      });
    };
    return {
      ...toRefs(data),
      toggleShow,
      select,
      dialog,
    };
  },
});
export default Words;
