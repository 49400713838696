
import { defineComponent, reactive, watchEffect, toRefs, computed } from "vue";

export default defineComponent({
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    selectedList: {
      type: Array,
      default: () => [],
    },
    isSearch: {
      type: Boolean,
      default: () => true,
    },
    isRadio: {
      type: Boolean,
      default: () => false,
    },
    isCustomItem: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => "",
    },
    type: {
      type: String,
      default: () => "",
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
    resultShow: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      type: "",
      list: [],
      originList: [],
      selectedList: [],
      isSearch: true,
      isCustomItem: false,
      title: "",
      input: "",
    });

    watchEffect(() => {
      if (props.isCustomItem) {
        for (let index = 0; index < props.list.length; index++) {
          if (props.list[index].conversationID.indexOf("@TIM#SYSTEM") > -1) {
            // eslint-disable-next-line vue/no-mutating-props
            props.list.splice(index, 1);
          }
          data.list = props.list;
          data.originList = props.list;
        }
      } else {
        data.list = props.list;
        data.originList = props.list;
      }
      data.selectedList = props.selectedList;
      data.isSearch = props.isSearch;
      data.isCustomItem = props.isCustomItem;
      data.title = props.title;
      data.type = props.type;
    });

    // 可选项
    const optional = computed(() =>
      data.list.filter((item: any) => {
        return !item.isDisabled && item.conversationID?.indexOf("server_") === -1;
      })
    );

    const handleInput = (value: string) => {
      handleSearch(value);
    };

    const handleClearInput = () => {
      handleSearch("");
    };

    const handleClick = () => {
      handleSearch(data.input);
    };

    // 搜索
    const handleSearch = (keyword: string) => {
      const listStr = JSON.stringify(data.originList);
      const searchList = JSON.parse(listStr);

      data.list = searchList.filter(
        (li: any) => li.userProfile?.nick.includes(keyword) || li.groupProfile?.name.includes(keyword)
      );
    };

    const selected = (item: any) => {
      if (item.isDisabled) {
        return;
      }
      let list: any = data.selectedList;
      const index: number = isSelected(list, item);
      if (index > -1) {
        return data.selectedList.splice(index, 1);
      }
      if (props.isRadio) {
        list = [];
      }
      list.push(item);
      data.selectedList = list;
    };

    const isSelected = (list: any[], item: any) => {
      return list.findIndex((li) => li.conversationID === item.conversationID);
    };
    const selectedAll = () => {
      if (data.selectedList.length === optional.value.length) {
        data.selectedList = [];
      } else {
        data.selectedList = [...optional.value];
      }
    };

    const submit = () => {
      ctx.emit("submit", data.selectedList);
    };

    const cancel = () => {
      ctx.emit("cancel");
    };

    return {
      ...toRefs(data),
      optional,
      handleInput,
      handleClearInput,
      handleClick,
      isSelected,
      selected,
      selectedAll,
      submit,
      cancel,
    };
  },
});
