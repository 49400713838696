
import { CUSTOM_MESSAGE_TYPE } from "@/config/types/common";
import { onClickOutside, useElementBounding } from "@vueuse/core";
import { computed, defineComponent, nextTick, reactive, ref, toRefs, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import { JSONToObject } from "../../../TUIChat/utils/utils";
import { Conversation } from "../../interface";
const ListItem: any = defineComponent({
  props: {
    conversation: {
      type: Object,
      default: () => ({}),
    },
    handleConversation: {
      type: Object,
      default: () => ({}),
    },
    currentID: {
      type: String,
      default: () => "",
    },
    toggleID: {
      type: String,
      default: () => "",
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
    displayOnlineStatus: {
      type: Boolean,
      default: () => false,
    },
    userStatusList: {
      type: Map,
      default: () => new Map(),
    },
    types: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore && useStore();
    const siteInfo = computed(() => store.state.siteInfo);
    const data = reactive({
      conversation: {} as Conversation,
      currentID: "",
      currentConversation: {},
      toggle: false,
      currentuserID: "",
      conversationType: "",
      loop: 0,
      displayOnlineStatus: false,
      userStatusList: new Map(),
      selfSignature: "", // 个性签名
      customInfo: {}, // 自定义信息，包含用户身份，用户 ID，伯主等级
    });

    const dialog: any = ref();
    const content: any = ref();

    onClickOutside(content, () => {
      if (data.toggle === true) {
        ctx.emit("toggle", "");
      }
    });

    watchEffect(async () => {
      data.conversation = props.conversation;
      data.currentID = props.currentID;
      data.toggle = false;
      data.displayOnlineStatus = props.displayOnlineStatus;
      data.userStatusList = props.userStatusList;
      props.toggleID === props.conversation.conversationID && (data.toggle = true);
      const [selfSignature, customInfo] = await props.handleConversation?.selfSignatureAndCustomInfo(
        props.conversation
      );
      data.selfSignature = selfSignature;
      data.customInfo = customInfo;
    });

    watch(
      () => data.toggle,
      (val: boolean) => {
        if (val) {
          nextTick(() => {
            const DialogBound = useElementBounding(dialog);
            const ParentEle = content?.value?.offsetParent;
            const ParentBound = useElementBounding(ParentEle);
            if (DialogBound.top.value - ParentBound.top.value - DialogBound.height.value - 30 > 0) {
              dialog.value.style.top = "auto";
              dialog.value.style.bottom = "30px";
            }
          });
        }
      }
    );

    const handleListItem = (item: any) => {
      const custom = JSONToObject(data.conversation.lastMessage?.payload?.data);
      const host_config = siteInfo.value.host_config;
      let job_host = process.env.VUE_APP_HOST;
      if (host_config) {
        const config = JSON.parse(host_config);
        const envConfig = props.isH5 ? config?.mobile : config?.pc;
        job_host = envConfig?.job_host || process.env.VUE_APP_HOST;
      }
      const url = custom?.url?.replace(process.env.VUE_APP_HOST, job_host);
      // H5
      if (props.isH5 && custom?.businessID === CUSTOM_MESSAGE_TYPE.SYSTEM_NOTICE) {
        window.location.href = url;
      } else {
        openChat({
          ...item,
          businessId: custom?.businessID,
          url: url,
          //url: `https://jobm.stac.fun/job`,
        });
      }
    };

    const openChat = (item: any) => {
      ctx.emit("open", item);
      ctx.emit("toggle", "");
    };
    const toggleDialog = (e?: any) => {
      if (e?.target?.oncontextmenu) {
        e.target.oncontextmenu = function () {
          return false;
        };
      }
      ctx.emit("toggle", (data.conversation as any).conversationID);
    };

    const handleItem = (name: string) => {
      ctx.emit("handle", {
        name,
        conversation: data.conversation,
      });
      ctx.emit("toggle", "");
    };

    const showUserOnlineStatus = () => {
      if (data.displayOnlineStatus && data.conversation?.type === props.types.CONV_C2C) return true;
      return false;
    };

    return {
      ...toRefs(data),
      handleListItem,
      handleItem,
      dialog,
      content,
      toggleDialog,
      showUserOnlineStatus,
    };
  },
});
export default ListItem;
