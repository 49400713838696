
import { defineComponent, reactive, watchEffect, toRefs, ref } from "vue";
import Transfer from "../../../../components/transfer/index.vue";
import { handleErrorPrompts } from "../../../utils";
import { onClickOutside } from "@vueuse/core";
import { Message } from "../../interface";
import { useStore } from "vuex";

const Forward = defineComponent({
  components: {
    Transfer,
  },
  name: "转发",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    message: {
      type: Object,
      default: () => ({}),
    },
    messages: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: () => false,
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
    forwardType: {
      type: String,
      default: () => "single",
    },
    forwardTitle: {
      type: String,
      default: () => "聊天记录",
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      list: [],
      show: false,
      to: -1,
      abstractList: [] as string[],
      originList: [],
    });

    const dialog: any = ref();

    watchEffect(() => {
      data.list = props.list;
      data.originList = props.list;
      data.show = props.show;

      props.messages.forEach((msg: Message) => {
        let content = "";
        switch (msg.type) {
          case "TIMTextElem":
            content = msg.payload?.text;
            break;
          case "TIMImageElem":
            content = "[图片]";
            break;
          case "TIMVideoFileElem":
            content = "[视频]";
            break;
          case "TIMCustomElem":
            content = msg.payload?.description || "[自定义消息]";
        }
        data.abstractList.push(`${msg.nick}:${content}`);
      });
    });

    const toggleShow = () => {
      data.show = !data.show;
      if (!data.show) {
        ctx.emit("update:show", data.show);
        data.to = -1;
      }
    };

    onClickOutside(dialog, () => {
      data.show = false;
      ctx.emit("update:show", data.show);
      data.to = -1;
    });

    const handleForWordMessage = async (list: any) => {
      list.map(async (item: any) => {
        try {
          if (props.forwardType === "merge") {
            await Forward.TUIServer.sendMergerMessage(
              {
                messageList: props.messages,
                title: props.forwardTitle,
                abstractList: data.abstractList,
                compatibleText: "请升级IMSDK到v2.10.1或更高版本查看此消息",
              },
              item
            );
          } else {
            for (const msg of props.messages) {
              await Forward.TUIServer.forwardMessage(msg, item);
            }
          }
        } catch (error) {
          handleErrorPrompts(error, props);
        }
      });
      ctx.emit("closeForward");
      toggleShow();
    };

    return {
      ...toRefs(data),
      toggleShow,
      handleForWordMessage,
      dialog,
    };
  },
});
export default Forward;
