import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f645e78a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message-tip" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(_ctx.message?.text), 1),
    (_ctx.message?.operateText)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createTextVNode("，"),
          _createElementVNode("a", {
            class: "message-tip-link",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleOperate && _ctx.handleOperate(...args)))
          }, _toDisplayString(_ctx.message.operateText), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}