// 性别
export enum GENDER {
  MALE = "114XDxDX", // 男
  FEMALE = "116ufbe1", // 女
}
// 5.0 接口状态码
export enum API_RESULT_CODE {
  SUCCESS = 200, // 成功
  PSW_NEED_CAPCTHA = 620101, // 密码登录时需要短信验证码
  AUTH_INCORRECT_USER_TYPE = 10002, // 用户类型不匹配
  AUTH_INVAILD_TOKEN = 10001, // token 无效
  UN_AUTHENTICATED = 401, // 账号类型错误或权限不足
  UN_REAL_NAME_AUTH = 50001, // 未实名认证
  NO_DISPLAY = 2204, // 不予展示
}
// 自定义消息类型
export enum CUSTOM_MESSAGE_TYPE {
  SYSTEM_NOTICE = "system_notice", // 系统消息
  JOB = "job", // 职位
  COMPANY = "company", // 单位
  SXH = "sxh", // 双选会
  RESUME = "resume", // 简历
  INVITE_APPLY = "invite_apply", // 邀投递
  SYSTEM_TIP = "system_tip", // 系统提示信息
  GREETING = "greeting", // 招呼语
  COMMUNITY = "community", // 社区动态卡片
  TOPIC = "topic", // 话题
  MAIN_PAGE = "mainpage", // 主页
}
export enum WageUnit {
  DAY = "114AygDP", // 日薪
  MONTH = "11jNfoC", // 月薪
}
