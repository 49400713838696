import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ce2c6fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { className: "layout-tab-bar" }
const _hoisted_2 = { className: "layout-tab-bar-content" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { className: "nav-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabBarList, (item) => {
        return (_openBlock(), _createElementBlock("a", {
          key: item.key,
          href: item.href,
          class: _normalizeClass(['nav-item', _ctx.active === item.key && 'active'])
        }, [
          _createVNode(_component_svg_icon, {
            iconClass: item.key,
            className: `icon ${_ctx.active === item.key ? 'icon-active' : 'icon-real'}`
          }, null, 8, ["iconClass", "className"]),
          _createElementVNode("span", _hoisted_4, _toDisplayString(item.title), 1)
        ], 10, _hoisted_3))
      }), 128))
    ])
  ]))
}