import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { TUIComponents, TUICore } from "./TUIKit";
import { TUICallKit } from "@tencentcloud/call-uikit-vue";
import { TUINotification } from "./TUIKit/TUIPlugin";
import locales from "../public/locales";
import ElementPlus from "element-plus";
import VueCookies from "vue-cookies";
import "element-plus/dist/index.css";
import "./public-path";
import SvgIcon from "@/components/svgIcon/SvgIcon.vue";
import "@/components/svgIcon";

const app = createApp(App);

function render(props: any = {}) {
  const { container } = props;
  const TUIKit = TUICore.init({
    SDKAppID: +process.env.VUE_APP_IM_SDK_APP_ID,
  });
  TUIKit.config.i18n.provideMessage(locales);
  TUIKit.use(TUIComponents);
  TUIKit.use(TUICallKit);
  TUIKit.use(TUINotification);
  app
    .component("svg-icon", SvgIcon)
    .use(store)
    .use(router)
    .use(TUIKit)
    .use(ElementPlus)
    .use(VueCookies)
    .mount(container ? container.querySelector("#app") : "#app");
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

/**
 * bootstrap ： 在微应用初始化的时候调用一次，之后的生命周期里不再调用
 */
export async function bootstrap() {
  console.log("bootstrap");
}

/**
 * mount ： 在应用每次进入时调用
 */
export async function mount(props: any) {
  console.log("mount", props);
  render(props);
}

/**
 * unmount ：应用每次 切出/卸载 均会调用
 */
export async function unmount() {
  console.log("unmount");
  app.unmount();
}
