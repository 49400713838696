import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d8154a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "member" }
const _hoisted_2 = { class: "list" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "list-item",
          key: index
        }, [
          _createElementVNode("aside", null, [
            _createElementVNode("img", {
              class: "avatar",
              src: item?.avatar || 'https://image.iguopin.com/data/app/im-logo.png',
              onerror: "this.src='https://image.iguopin.com/data/app/im-logo.png'"
            }, null, 8, _hoisted_3),
            _createElementVNode("span", _hoisted_4, _toDisplayString(item?.nick || item?.userID), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.handleRoleName(item)), 1)
          ])
        ]))
      }), 128)),
      (_ctx.list.length < _ctx.total)
        ? (_openBlock(), _createElementBlock("li", {
            key: 0,
            class: "list-item",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.getMore && _ctx.getMore(...args)))
          }, _toDisplayString(_ctx.$t(`TUIChat.manage.查看更多`)), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}