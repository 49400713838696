import { request, requestApp } from "../utils/request";
import { _cookie } from "@/utils/constant";

const appHead = {
  channel: "h5",
  is_first: 0,
  language: "zh",
  os: "h5",
  screen: "720*1560",
  time: (new Date().getTime() / 1000).toFixed(0),
  type: "h5",
  user_agent: "MI CC 9e_Android 10 API 29",
  user_id: "",
  version: "5.0.0",
};
/**
 * C端我的简历列表
 * see http://apidoc.stac.fun/project/42/interface/api/3906
 */
export const getResumeList = () => {
  return request({
    url: "/personal/resume/v1/list",
    method: "POST",
    data: { mod_type: "resume", last_edit: false, need_template: false },
  }) as any;
};
export function getResumeListViaApp(token: string) {
  return requestApp({
    url: "/api",
    method: "post",
    data: {
      body: { mod_type: "resume", last_edit: false, need_template: false },
      head: {
        ...appHead,
        action: 8270,
        token,
      },
    },
  }) as any;
}

/**
 * C端邀投递卡片状态
 * see http://apidoc.stac.fun/project/42/interface/api/9835
 */
export const inviteApplyList = (invite_ids: string[]) => {
  return request({
    url: "/personal/apply/invite/v1/invite-apply-list",
    method: "POST",
    data: { invite_ids },
  }) as any;
};
export function inviteApplyListViaApp(invite_ids: string[], token: string) {
  return requestApp({
    url: "/api",
    method: "post",
    data: {
      body: { invite_ids },
      head: {
        ...appHead,
        action: 8290,
        token,
      },
    },
  }) as any;
}

/**
 * C端邀投递卡片拒绝
 * see http://apidoc.stac.fun/project/42/interface/api/9840
 */
export const rejectInviteApply = (invite_id: string) => {
  return request({
    url: "/personal/apply/invite/v1/reject-invite-apply",
    method: "POST",
    data: { invite_id },
  }) as any;
};
export function rejectInviteApplyViaApp(invite_id: string, token: string) {
  return requestApp({
    url: "/api",
    method: "post",
    data: {
      body: { invite_id },
      head: {
        ...appHead,
        action: 8291,
        token,
      },
    },
  }) as any;
}

/**
 * B端获取企业职位列表
 * see http://apidoc.stac.fun/project/24/interface/api/2540
 */
export const getJobList = (keyword?: string) => {
  return request({
    url: "/company/jobs/v1/list",
    method: "POST",
    data: { keyword, page_size: 100, status: 1 },
  }) as any;
};
export function getJobListViaApp(token: string, keyword?: string) {
  return requestApp({
    url: "/api",
    method: "post",
    data: {
      body: { keyword, page_size: 100, status: [1] },
      head: {
        ...appHead,
        action: 8327,
        token,
      },
    },
  }) as any;
}

/**
 * 邀请投递
 * see http://apidoc.stac.fun/project/24/interface/api/9705
 */
export const inviteApply = (job_id: string, receive_user_id: string) => {
  return request({
    url: "/company/apply/resume/v1/invite-apply",
    method: "POST",
    data: { job_id, receive_user_id },
  }) as any;
};
export function inviteApplyViaApp(job_id: string, receive_user_id: string, job_name: string, token: string) {
  return requestApp({
    url: "/api",
    method: "post",
    data: {
      body: { job_id, receive_user_id, job_name },
      head: {
        ...appHead,
        action: 8349,
        token,
      },
    },
  }) as any;
}

/**
 * 职位详情接口（传职位id\个人用户id，职位详情里得包含个人用户的投递状态）
 * see http://apidoc.stac.fun/project/132/interface/api/4116
 */
export const getJobInfo = (id: string, user_id: string) => {
  return request({
    url: "/api/jobs/v1/info",
    method: "POST",
    data: { id, user_id },
  }) as any;
};
export function getJobInfoViaApp(id: string, user_id: string, token: string) {
  return requestApp({
    url: "/api",
    method: "post",
    data: {
      body: { id, user_id },
      head: {
        ...appHead,
        action: 8257,
        token,
      },
    },
  }) as any;
}

/**
 * 分享简历
 * see http://apidoc.stac.fun/project/42/interface/api/9695
 */
export const shareResume = (resume_id: string, is_default: boolean) => {
  return request({
    url: "/personal/resume/v1/resume-share",
    method: "POST",
    data: { resume_id, is_default },
  }) as any;
};
export function shareResumeViaApp(resume_id: string, is_default: boolean, token: string) {
  return requestApp({
    url: "/api",
    method: "post",
    data: {
      body: { resume_id, is_default },
      head: {
        ...appHead,
        action: 8289,
        token,
      },
    },
  }) as any;
}

/**
 * B端邀投递卡片状态
 * see http://apidoc.stac.fun/project/42/interface/api/9870
 */
export const inviteBApplyList = (invite_ids: string[]) => {
  return request({
    url: "/company/apply/invite/v1/invite-apply-list",
    method: "POST",
    data: { invite_ids },
  }) as any;
};
export function inviteBApplyListViaApp(invite_ids: string[], token: string) {
  return requestApp({
    url: "/api",
    method: "post",
    data: {
      body: { invite_ids },
      head: {
        ...appHead,
        action: 8350,
        token,
      },
    },
  }) as any;
}

/**
 * C端投递详情
 * see http://apidoc.stac.fun/project/42/interface/api/6520
 */
export const applyInfo = (apply_id: string) => {
  return request({
    url: "/personal/apply/resume/v1/info",
    method: "POST",
    data: { apply_id },
  }) as any;
};
export function applyInfoViaApp(apply_id: string, token: string) {
  return requestApp({
    url: "/api",
    method: "post",
    data: {
      body: { apply_id },
      head: {
        ...appHead,
        action: 8292,
        token,
      },
    },
  }) as any;
}

/**
 * id 获取职位详情
 * see http://apidoc.stac.fun/project/132/interface/api/4116
 * id:职位ID,user_id:投递人 id
 */
export const jobInfo = (id: string, user_id: string) => {
  return request({
    url: "/api/jobs/v1/info",
    method: "GET",
    params: { id, user_id },
  }) as any;
};
// 聊天窗口调用该接口获取是否有职位吸顶
// {"user_id":"对方user id","im_id":"对方im id"}
export function jobInfoViaApp(im_id: string, user_id: string, token: string) {
  return requestApp({
    url: "/api",
    method: "post",
    data: {
      body: { im_id, user_id },
      head: {
        ...appHead,
        action: 8514,
        token,
      },
    },
  }) as any;
}
// 获取职位卡片的投递相关状态
// {"user_id":"对方user id","job_id":"职位id"}
export function jobApplyStatusViaApp(job_id: string, user_id: string, token: string) {
  return requestApp({
    url: "/api",
    method: "post",
    data: {
      body: { job_id, user_id },
      head: {
        ...appHead,
        action: 8517,
        token,
      },
    },
  }) as any;
}

// 聊天窗口调用该接口获取是否有职位吸顶
// {"user_id":"对方user id","im_id":"对方im id"}
// follow_type：关注类型 0 陌生人 1我的关注 2 互关好友 3 我的粉丝
export function userFollowViaApp(im_id: string, user_id: string, token: string) {
  return requestApp({
    url: "/api",
    method: "post",
    data: {
      body: { im_id, user_id },
      head: {
        ...appHead,
        action: 8518,
        token,
      },
    },
  }) as any;
}
