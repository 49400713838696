
import { defineComponent, reactive, toRefs, computed, watch } from "vue";
import TUIConversationList from "./components/list";
import { caculateTimeago, isArrayEqual } from "../utils";
import { handleAvatar, handleName, handleShowLastMessage, handleAt, JSONToObject } from "../TUIChat/utils/utils";
import TIM from "@/TUIKit/TUICore/tim";

const TUIConversation = defineComponent({
  name: "TUIConversation",

  components: {
    TUIConversationList,
  },
  props: {
    displayOnlineStatus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const TUIServer: any = TUIConversation?.TUIServer;
    const data = reactive({
      currentConversationID: "",
      conversationData: {
        list: [],
        handleItemAvator: (item: any) => handleAvatar(item),
        handleItemName: (item: any) => handleName(item),
        handleShowAt: (item: any) => handleAt(item),
        handleShowMessage: (item: any) => handleShowLastMessage(item),
        handleItemTime: (time: number) => {
          if (time > 0) {
            return caculateTimeago(time * 1000);
          }
          return "";
        },
        handleItemSelfSignatureAndCustomInfo: async (item: any) => {
          let selfSignature = "";
          let customInfoVal = {};
          if (item.type === TIM.TYPES.CONV_C2C) {
            const obj = await TUIServer.getUserProfile([item.userProfile?.userID]);
            const { selfSignature: sign, profileCustomField } = obj.data?.[0];
            const customInfo = profileCustomField.filter((item: any) => item.key === "Tag_Profile_Custom_Info")?.[0]
              ?.value;
            selfSignature = sign;
            customInfoVal = JSONToObject(customInfo);
          }
          return [selfSignature, customInfoVal];
        },
      },
      userIDList: [],
      netWork: "",
      env: TUIServer.TUICore.TUIEnv,
      displayOnlineStatus: false,
      userStatusList: new Map(),
      loading: false,
    });

    TUIServer.bind(data);

    TUIConversationList.TUIServer = TUIServer;

    watch(
      () => data.currentConversationID,
      (newVal: any) => {
        ctx.emit("current", newVal);
      },
      {
        deep: true,
      }
    );

    watch(
      () => props.displayOnlineStatus,
      async (newVal: any, oldVal: any) => {
        if (newVal === oldVal) return;
        data.displayOnlineStatus = newVal;
        TUIServer.TUICore.TUIServer.TUIContact.handleUserStatus(data.displayOnlineStatus, [...data.userIDList]);
      },
      { immediate: true }
    );

    watch(
      () => [...data.userIDList],
      async (newVal: any, oldVal: any) => {
        if (isArrayEqual(newVal, oldVal)) return;
        TUIServer.TUICore.TUIServer.TUIContact.handleUserStatus(data.displayOnlineStatus, [...data.userIDList]);
      },
      {
        deep: true,
      }
    );

    const isNetwork = computed(() => {
      const disconnected = data.netWork === TUIServer.TUICore.TIM.TYPES.NET_STATE_DISCONNECTED;
      const connecting = data.netWork === TUIServer.TUICore.TIM.TYPES.NET_STATE_CONNECTING;
      return disconnected || connecting;
    });

    const handleCurrentConversation = (value: any) => {
      TUIServer.handleCurrentConversation(value);
    };

    return {
      ...toRefs(data),
      handleCurrentConversation,
      isNetwork,
    };
  },
});
export default TUIConversation;
