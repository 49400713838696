
import { defineComponent, watchEffect, reactive, toRefs } from 'vue';
import TIM from '@tencentcloud/chat';

const ManageMember = defineComponent({
  components: {},
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: () => 0,
    },
    isShowDel: {
      type: Boolean,
      default: () => false,
    },
    self: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, ctx: any) {
    const types: any = TIM.TYPES;
    const data: any = reactive({
      total: 0,
      list: [],
      isShowDel: false,
      self: {},
    });

    watchEffect(() => {
      data.total = props.total;
      data.isShowDel = props.isShowDel;
      data.list = props.list;
      data.self = props.self;
    });

    const handleRoleName = (item: any) => {
      const { t } = (window as any).TUIKitTUICore.config.i18n.useI18n();
      let name = "";
      switch (item?.role) {
        case types.GRP_MBR_ROLE_ADMIN:
          name = t("TUIChat.manage.管理员");
          break;
        case types.GRP_MBR_ROLE_OWNER:
          name = t("TUIChat.manage.群主");
          break;
      }
      if (name) {
        name = `(${name})`;
      }
      if (item.userID === data.self.userID) {
        name += ` (${t("TUIChat.manage.我")})`;
      }
      return name;
    };

    const getMore = () => {
      ctx.emit("more");
    };

    const submit = (item: any) => {
      ctx.emit("del", [item]);
    };

    const handleMemberProfileShow = (user: any) => {
      ctx.emit("handleMemberProfileShow", user);
    };

    return {
      ...toRefs(data),
      getMore,
      submit,
      handleRoleName,
      handleMemberProfileShow,
    };
  },
});
export default ManageMember;
