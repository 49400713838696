import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03a846c3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "transfer-h5-header"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "main" }
const _hoisted_4 = { class: "left" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "list-empty"
}
const _hoisted_7 = {
  key: 1,
  class: "list"
}
const _hoisted_8 = {
  key: 0,
  class: "list-item"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 0,
  class: "list-item-flex"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "name" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "right" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "list" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = ["src"]
const _hoisted_19 = { key: 0 }
const _hoisted_20 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["transfer", [_ctx.isH5 ? 'transfer-h5' : '']])
  }, [
    (_ctx.isH5)
      ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
          _createElementVNode("i", {
            class: "icon icon-back",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
          }),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("main", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.isSearch)
          ? (_openBlock(), _createElementBlock("header", _hoisted_5, [
              _createVNode(_component_el_input, {
                modelValue: _ctx.input,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.input) = $event)),
                clearable: "",
                type: "text",
                placeholder: "请输入昵称",
                onChange: _ctx.handleInput,
                onClear: _ctx.handleClearInput
              }, {
                append: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    class: "input-search-btn",
                    type: "primary",
                    onClick: _ctx.handleClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("搜索")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["modelValue", "onChange", "onClear"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("main", null, [
          (_ctx.list.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, "搜索无结果"))
            : (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                (_ctx.optional.length > 1 && !_ctx.isRadio)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                      _createVNode(_component_el_button, {
                        onClick: _ctx.selectedAll,
                        size: "small",
                        plain: "",
                        class: "all"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("component.全选")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "list-item",
                    key: index,
                    onClick: ($event: any) => (_ctx.selected(item))
                  }, [
                    (!item.conversationID || item.conversationID?.indexOf('server_') === -1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          (_ctx.isSelected(_ctx.selectedList, item) > -1)
                            ? (_openBlock(), _createBlock(_component_svg_icon, {
                                key: 0,
                                iconClass: "selected",
                                className: "icon-selected"
                              }))
                            : (_openBlock(), _createElementBlock("i", {
                                key: 1,
                                class: _normalizeClass(["icon", [item?.isDisabled && 'disabled', 'icon-unselected']])
                              }, null, 2)),
                          (!_ctx.isCustomItem)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                _createElementVNode("img", {
                                  class: "avatar",
                                  src: item?.avatar || 'https://image.iguopin.com/data/app/im-logo.png',
                                  onerror: "this.src='https://image.iguopin.com/data/app/im-logo.png'"
                                }, null, 8, _hoisted_11),
                                _createElementVNode("span", _hoisted_12, _toDisplayString(item?.nick || item?.userID), 1),
                                (item?.isDisabled)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_13, "（" + _toDisplayString(_ctx.$t("component.已在群聊中")) + "）", 1))
                                  : _createCommentVNode("", true)
                              ], 64))
                            : _renderSlot(_ctx.$slots, "left", {
                                key: 3,
                                data: item
                              }, undefined, true)
                        ]))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_9))
                }), 128))
              ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        (!_ctx.isH5)
          ? (_openBlock(), _createElementBlock("header", _hoisted_15, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("ul", _hoisted_16, [
          (_ctx.selectedList.length > 0 && !_ctx.isH5)
            ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(_ctx.$t("component.已选中")) + _toDisplayString(_ctx.selectedList.length) + _toDisplayString(_ctx.$t("component.人")), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedList, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "list-item space-between",
              key: index
            }, [
              _createElementVNode("aside", null, [
                (!_ctx.isCustomItem)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("img", {
                        class: "avatar",
                        src: item?.avatar || 'https://image.iguopin.com/data/app/im-logo.png',
                        onerror: "this.src='https://image.iguopin.com/data/app/im-logo.png'"
                      }, null, 8, _hoisted_18),
                      (!_ctx.isH5)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(item.nick || item.userID), 1))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _renderSlot(_ctx.$slots, "right", {
                      key: 1,
                      data: item
                    }, undefined, true)
              ]),
              (!_ctx.isH5)
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    class: "icon icon-cancel",
                    onClick: ($event: any) => (_ctx.selected(item))
                  }, null, 8, _hoisted_20))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ], 512), [
          [_vShow, _ctx.resultShow]
        ]),
        _createElementVNode("footer", null, [
          _createElementVNode("button", {
            class: "btn btn-cancel",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
          }, _toDisplayString(_ctx.$t("component.取消")), 1),
          (_ctx.selectedList.length > 0)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
              }, _toDisplayString(_ctx.$t("component.完成")), 1))
            : (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn btn-no",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
              }, _toDisplayString(_ctx.$t("component.完成")), 1))
        ])
      ])
    ])
  ], 2))
}