
import { defineComponent, watchEffect, reactive, toRefs } from "vue";

const ManageNotification = defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isAuth: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const data: any = reactive({
      groupProfile: {},
      input: "",
      isEdit: false,
    });

    watchEffect(() => {
      data.groupProfile = props.data;
      data.input = data.groupProfile.notification;
    });

    // 更新群资料
    const updateProfile = async () => {
      if (data.input && data.input !== data.groupProfile.notification) {
        ctx.emit("update", { key: "notification", value: data.input });
        data.groupProfile.notification = data.input;
        data.input = "";
      }
      data.isEdit = !data.isEdit;
    };

    return {
      ...toRefs(data),
      updateProfile,
    };
  },
});
export default ManageNotification;
