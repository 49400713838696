import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Transfer = _resolveComponent("Transfer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.show)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["forward", [_ctx.isH5 ? 'forward-h5' : '']]),
          ref: "dialog"
        }, [
          _createVNode(_component_Transfer, {
            title: "转发",
            list: _ctx.list,
            isSearch: true,
            isH5: _ctx.isH5,
            isCustomItem: true,
            resultShow: true,
            onSubmit: _ctx.handleForWordMessage,
            onCancel: _ctx.toggleShow
          }, {
            left: _withCtx(({ data }) => [
              _renderSlot(_ctx.$slots, "left", { data: data }, undefined, true)
            ]),
            right: _withCtx(({ data }) => [
              _renderSlot(_ctx.$slots, "right", { data: data }, undefined, true)
            ]),
            _: 3
          }, 8, ["list", "isH5", "onSubmit", "onCancel"])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}