
import { defineComponent, reactive, toRefs, watchEffect } from "vue";
import { Message } from "../../interface";
import {
  handleTextMessageShowContext,
  handleCustomMessageShowContext,
  handleFileMessageShowContext,
} from "../../utils/utils";
import { MessageText, MessageEmojiReact } from "../../components";
import { caculateTimeago } from "../../../utils";
import constant from "../../../constant";
import TIM from "../../../../../TUICore/tim/index";
const RepliesItem = defineComponent({
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    isH5: {
      type: Boolean,
      default: false,
    },
    isRoot: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MessageText,
    MessageEmojiReact,
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      message: {} as Message,
      isH5: false,
      url: "",
      isRoot: false,
      constant,
      TIM,
    });
    watchEffect(() => {
      data.message = props.message;
      data.isH5 = props.isH5;
      data.isRoot = props.isRoot;
      if (data.message.type === TIM.TYPES.MSG_FACE) {
        data.url = `https://web.sdk.qcloud.com/im/assets/face-elem/${data.message?.payload?.data}@2x.png`;
      }
    });
    return {
      ...toRefs(data),
      handleTextMessageShowContext,
      handleCustomMessageShowContext,
      handleFileMessageShowContext,
      caculateTimeago,
    };
  },
});
export default RepliesItem;
