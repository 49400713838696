import Axios from "axios";
import { _cookie } from "./constant";
import { resetUserInfo, trimData } from "./utils";
import { API_RESULT_CODE } from "@/config/types/common";
import { ElMessage } from "element-plus";
import VueCookies from "vue-cookies";

const Cookies: any = VueCookies;

/**
 * 创建不同 baseUrl 的请求实例
 * @param baseUrl
 * @returns
 */
const createAxiosInstance = (baseURL: string) => {
  // create an axios instance
  const axios = Axios.create({
    baseURL,
    timeout: 30000, // request timeout
  });

  // request interceptor
  axios.interceptors.request.use(
    (config) => {
      config.data = trimData(config.data);
      config.params = trimData(config.params);
      const token = Cookies?.get(_cookie.TOKEN);

      if (token) {
        if (config.headers) {
          config.headers["ApiAuth"] = token;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // response interceptor
  axios.interceptors.response.use(
    (response) => {
      const res = response.data;
      const code = res?.code;
      const token = Cookies?.get(_cookie.TOKEN);
      if (
        (!token && code === API_RESULT_CODE.UN_AUTHENTICATED) || // 须登录
        code === API_RESULT_CODE.AUTH_INVAILD_TOKEN // 登录状态失效
      ) {
        resetUserInfo();
        const siteInfo = JSON.parse(localStorage.getItem("SiteInfo") || "");
        const host_config = siteInfo.value.host_config;
        let job_host = process.env.VUE_APP_HOST;
        if (host_config) {
          const config = JSON.parse(host_config);
          job_host = config?.pc?.job_host || process.env.VUE_APP_HOST;
        }
        window.location.href = `${job_host}/login?redirect=${encodeURIComponent(window.location.href)}`;
        return Promise.reject(null);
      }
      return Promise.resolve(res);
    },
    (error) => {
      if (Axios.isCancel(error)) {
        ElMessage.error({
          message: error.message || "请求取消",
          duration: 2000,
          onClose: () => {
            window.location.reload();
          },
        });
        return Promise.reject(null);
      }
      const { response } = error;
      if (response) {
        // 请求已发出，但是不在2xx的范围
        errorHandle(response);
      } else {
        // 处理断网的情况
        // eg:请求超时或断网时，更新state的network状态
        // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
        // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
        ElMessage.error("当前系统访问人数过多，请稍后重试");
      }
      if (response && response.data) {
        return Promise.reject(response.data);
      }
      return Promise.reject(null);
    }
  );

  function errorHandle(res: any) {
    // 状态码判断
    switch (res.status) {
      case 401:
        ElMessage.error("请求未授权");
        break;
      case 403:
        ElMessage.error("服务器拒绝访问");
        break;
      case 404:
        ElMessage.error("请求的资源不存在");
        break;
      default:
        ElMessage.error("连接错误");
    }
  }

  return axios;
};

export const request = createAxiosInstance(process.env.VUE_APP_BASE_API);
export const requestApp = createAxiosInstance(process.env.VUE_APP_APP_API);

export default request;
