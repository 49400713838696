
import { defineComponent, reactive, watchEffect, toRefs, ref, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import { Conversation } from "../../../TUIConversation/interface";
import Dialog from "../../../../components/dialog";
import Transfer from "../../../../components/transfer";
import Link from "../../../../../utils/link/index";
import { useStore } from "vuex";

const Call = defineComponent({
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    isMute: {
      type: Boolean,
      default: () => false,
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
    conversation: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Dialog,
    Transfer,
  },
  setup(props: any, ctx: any) {
    const { t } = Call.TUIServer.TUICore.config.i18n.useI18n();
    const store = useStore && useStore();
    const VuexStore = ((window as any)?.TUIKitTUICore?.isOfficial && useStore && useStore()) || {};
    const data = reactive({
      showSelectDialog: false,
      showCall: false,
      showGroupUserDialog: false,
      isH5: false,
      isMute: false,
      isHomeMenuOpen: false,
      conversation: {} as Conversation,
      showTitle: "",
      searchUserList: [],
      memberList: [],
      mediaType: 0,
      showUnsupportDialog: false,
      errorContent: "",
      errorLink: {},
      chat_setting: {
        c_realtime_audio: "false",
        c_realtime_video: "false",
        group_realtime_audio: "false",
        group_realtime_video: "false",
      },
    });

    const dialog: any = ref();

    watchEffect(() => {
      data.showSelectDialog = props.show;
      data.isMute = props.isMute;
      data.isH5 = props.isH5;
      data.conversation = props.conversation;
      data.chat_setting = store.state.userInfo.chat_setting;
    });

    watch(
      () => data.showCall,
      (newVal: boolean, oldVal: boolean) => {
        if (newVal === oldVal) return;
        if (data.showCall) {
          handleCallDialogPosition();
        }
      }
    );

    const toggleShowSelectDialog = () => {
      if (!data.isMute) {
        data.showSelectDialog = true;
      }
    };

    onClickOutside(dialog, () => {
      data.showSelectDialog = false;
    });

    const handleCallDialogPosition = () => {
      data.isHomeMenuOpen = !!document?.getElementsByClassName("home-menu")?.length;
    };

    const onClickCall = (mediaType: number) => {
      data.showSelectDialog = false;
      data.mediaType = mediaType;
      if (!Call?.TUIServer?.TUICore?.TUIServer?.TUICallKit) {
        handleUnsupportDialog();
        return;
      }
      (window as any)?.TUIKitTUICore?.isOfficial && VuexStore?.commit && VuexStore?.commit('handleTask', 6);
      switch (data.conversation?.type) {
        case Call.TUIServer.TUICore.TIM.TYPES.CONV_C2C:
          handleCall(data.conversation, mediaType);
          break;
        case Call.TUIServer.TUICore.TIM.TYPES.CONV_GROUP:
          handleGroupDialog(mediaType);
          break;
        default:
          break;
      }
    };

    const handleCall = async (
      conversation: Conversation,
      mediaType: number,
      userIDList: Array<string> = []
    ) => {
      const conversationType = conversation.type;
      switch (conversationType) {
        case Call.TUIServer.TUICore.TIM.TYPES.CONV_C2C:
          try {
            await Call.TUIServer.TUICore.TUIServer.TUICallKit.call({
              userID: conversation?.userProfile?.userID,
              type: mediaType,
            });
          } catch (error) {
            handleUnsupportDialog(error);
          }
          break;
        case Call.TUIServer.TUICore.TIM.TYPES.CONV_GROUP:
          try {
            await Call.TUIServer.TUICore.TUIServer.TUICallKit.groupCall({
              userIDList,
              groupID: conversation?.groupProfile?.groupID,
              type: mediaType,
            });
          } catch (error) {
            handleUnsupportDialog(error);
          }
          break;
        default:
          break;
      }
    };

    const handleShowCallIcon = (conversation: Conversation, isH5: boolean) => {
      // 单聊不支持音视频
      if (
        conversation.type === Call.TUIServer.TUICore.TIM.TYPES.CONV_C2C &&
        data.chat_setting?.c_realtime_audio !== "open" &&
        data.chat_setting?.c_realtime_video !== "open"
      ) {
        return false;
      }
      // 群聊不支持音视频
      if (
        conversation.type === Call.TUIServer.TUICore.TIM.TYPES.CONV_GROUP &&
        data.chat_setting?.group_realtime_audio !== "open" &&
        data.chat_setting?.group_realtime_video !== "open"
      ) {
        return false;
      }
      if (
        conversation.type === Call.TUIServer.TUICore.TIM.TYPES.CONV_C2C &&
        conversation?.userProfile?.userID ===
          Call?.TUIServer?.TUICore?.TUIServer?.TUIProfile?.currentStore?.profile?.userID
      ) {
        return false;
      }
      return true;
    };

    const handleGroupDialog = (mediaType: number) => {
      data.showGroupUserDialog = true;
      data.showTitle = mediaType === 1 ? t("TUIChat.发起群语音") : t("TUIChat.发起群视频");
      const options: any = {
        groupID: data?.conversation?.groupProfile?.groupID,
        count: 100,
        offset: 0,
      };
      Call?.TUIServer?.TUICore?.TUIServer?.TUIGroup?.getGroupMemberList(options)?.then(
        (res: any) => {
          const myUserID: any = data.conversation?.groupProfile?.selfInfo?.userID;
          let memberList = [];
          if (data?.conversation?.groupProfile?.muteAllMembers) {
            memberList = [];
          } else {
            const time: number = new Date().getTime();
            memberList = res.data.memberList.filter((item: any) => {
              if (item?.userID === myUserID) return false;
              if ((item as any)?.muteUntil * 1000 - time > 0) {
                return false;
              }
              return item;
            });
          }
          if (data?.conversation?.groupProfile?.muteAllMembers) {
            memberList = [];
          }
          data.memberList = memberList;
          data.searchUserList = memberList;
        }
      );
    };

    const handleSearch = async (val: any) => {
      const user = data.memberList?.filter((item: any) => item?.nick.indexOf(val) !== -1);
      data.searchUserList = user?.length ? user : data.memberList;
    };

    const submit = (userList: any) => {
      data.searchUserList = data.memberList;
      data.showGroupUserDialog = false;
      const userIDList = userList?.map((item: any) => item?.userID);
      handleCall(data.conversation, data.mediaType, userIDList);
    };

    const cancle = () => {
      data.showGroupUserDialog = false;
      data.mediaType = 0;
    };

    const openLink = (url: any) => {
      if (url) {
        window.open(url);
      }
    };

    const handleUnsupportDialog = (error?: any) => {
      if (!error) {
        data.showUnsupportDialog = true;
        data.errorContent = t("TUIChat.检测到您暂未集成TUICallKit，无法体验音视频通话功能");
        data.errorLink = Link.implement;
      } else if (
        error?.message?.indexOf("The package you purchased does not support this ability.") >= 0
      ) {
        data.showUnsupportDialog = true;
        data.errorContent = t("TUIChat.您当前购买使用的套餐包暂未开通此功能");
        data.errorLink = Link.purchase;
      } else {
        data.errorContent = error?.message || error;
        data.showUnsupportDialog = false;
        data.errorLink = {};
      }
    };

    return {
      ...toRefs(data),
      toggleShowSelectDialog,
      dialog,
      onClickCall,
      handleCallDialogPosition,
      handleCall,
      handleGroupDialog,
      handleSearch,
      submit,
      cancle,
      handleShowCallIcon,
      openLink,
      handleUnsupportDialog,
    };
  },
});
export default Call;
