
import { defineComponent, watch, reactive, toRefs, ref, watchEffect } from "vue";
import { Message } from "../interface";
import TIM from "../../../../TUICore/tim";
import { handleErrorPrompts } from "../../utils";
import constant from "../../constant";
import useClipboard from "vue-clipboard3";
import { useStore } from "vuex";
import MessageEmojiReact from "./message-emoji-react.vue";
import { JSONToObject } from "../utils/utils";
import { CUSTOM_MESSAGE_TYPE } from "@/config/types/common";
export default defineComponent({
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    needEmojiReact: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MessageEmojiReact,
  },
  setup(props: any, ctx: any) {
    const TUIServer = (window as any)?.TUIKitTUICore?.TUIServer?.TUIChat;
    const store = useStore && useStore();
    const VuexStore = ((window as any)?.TUIKitTUICore?.isOfficial && useStore && useStore()) || {};
    const data = reactive({
      message: {} as Message,
      show: false,
      types: TIM.TYPES,
      env: TUIServer.TUICore.TUIEnv,
      showToolList: true,
      needEmojiReact: false,
      msg_back_time: 2,
      inviteMessage: false,
    });

    watchEffect(() => {
      data.needEmojiReact = props.needEmojiReact;
      data.msg_back_time = store.state.userInfo?.msg_back_time || 2;
    });

    watch(
      () => props.message,
      () => {
        data.message = props.message;
        if (data.message.type === data.types.MSG_CUSTOM) {
          const isCustom = data.message.payload?.data;
          if (isCustom) {
            const isCustomObj = JSONToObject(isCustom);
            data.inviteMessage = isCustomObj.businessID === CUSTOM_MESSAGE_TYPE.INVITE_APPLY;
          }
        }
      },
      { deep: true, immediate: true }
    );

    const openMessage = (item: any) => {
      let url = "";
      switch (item.type) {
        case data.types.MSG_FILE:
          url = item.payload.fileUrl;
          break;
        case data.types.MSG_VIDEO:
          url = item.payload.remoteVideoUrl;
          break;
        case data.types.MSG_IMAGE:
          url = item.payload.imageInfoArray[0].url;
          break;
      }
      window.open(url, "_blank");
    };

    const handleMessage = async (message: Message, type: string) => {
      switch (type) {
        case constant.handleMessage.revoke:
          try {
            await TUIServer.revokeMessage(message);
            (window as any)?.TUIKitTUICore?.isOfficial && VuexStore?.commit && VuexStore?.commit("handleTask", 1);
          } catch (error) {
            handleErrorPrompts(error, data.env);
          }
          break;
        case constant.handleMessage.copy:
          try {
            if (message?.type === data.types.MSG_TEXT && message?.payload?.text) {
              const { toClipboard } = useClipboard();
              await toClipboard(message?.payload?.text);
            }
          } catch (error) {
            handleErrorPrompts(error, data.env);
          }
          break;
        case constant.handleMessage.delete:
          await TUIServer.deleteMessage([message]);
          break;
        case constant.handleMessage.forward:
          ctx.emit("handleMessage", message, constant.handleMessage.forward);
          break;
        case constant.handleMessage.reference:
          ctx.emit("handleMessage", message, constant.handleMessage.reference);
          break;
        case constant.handleMessage.reply:
          ctx.emit("handleMessage", message, constant.handleMessage.reply);
          break;
        case constant.handleMessage.multipleChoice:
          ctx.emit("handleMessage", message, constant.handleMessage.multipleChoice);
      }
    };

    const handleCollapse = (isCollapse: boolean) => {
      if (!data?.env?.isH5) return;
      data.showToolList = isCollapse;
    };

    return {
      ...toRefs(data),
      openMessage,
      handleMessage,
      constant,
      handleCollapse,
    };
  },
});
