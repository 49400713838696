
import { ElMessage } from "element-plus";
import { defineComponent, reactive, toRef, watchEffect } from "vue";

export default defineComponent({
  props: {
    jobHost: {
      type: String,
      default: () => "",
    },
    isCustom: {
      type: Object,
      default: () => ({}),
    },
    userType: {
      type: String,
      default: () => "personal",
    },
    imId: {
      type: String,
      default: () => "",
    },
  },
  setup(props) {
    const data = reactive({
      jobHost: "",
      isCustom: {},
      userType: "",
      imId: "",
    });

    watchEffect(() => {
      data.jobHost = props.jobHost;
      data.isCustom = props.isCustom;
      data.userType = props.userType;
      data.imId = props.imId;
    });

    // 话题分享
    const toHomePageDetail = () => {
      window.open(`${data.jobHost}/community/profile?im_id=${data.imId}&user_type=${(data.isCustom as any)?.userType}`);
    };
    return {
      ...toRef(data),
      toHomePageDetail,
    };
  },
});
