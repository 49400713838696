
import { defineComponent, reactive, toRefs, onMounted, watchEffect, inject, computed } from "vue";
import { isUrl, JSONToObject } from "../utils/utils";
import constant from "../../constant";
import { useStore } from "vuex";
import { API_RESULT_CODE, CUSTOM_MESSAGE_TYPE } from "@/config/types/common";
import { isNumber } from "lodash";
import { inviteApplyListViaApp, inviteBApplyListViaApp, jobApplyStatusViaApp, rejectInviteApply } from "@/api/resume";
import { _cookie } from "@/utils/constant";
import { UserType } from "@/config/types/user";
import { VueCookies } from "vue-cookies";
import { ElMessage } from "element-plus";
import ShareHomepage from "../custom-message/share-homepage.vue";

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    imId: {
      type: String,
      default: () => "",
    },
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ShareHomepage,
  },
  setup(props: any, ctx: any) {
    const VuexStore = ((window as any)?.TUIKitTUICore?.isOfficial && useStore && useStore()) || {};
    const store = useStore && useStore();
    const siteInfo = computed(() => store.state.siteInfo);
    const TUIServer = (window as any)?.TUIKitTUICore?.TUIServer?.TUIChat;
    const ProfileServer = TUIServer?.TUICore?.TUIServer?.TUIProfile;
    const $cookies = inject<VueCookies>("$cookies");

    const data = reactive({
      data: {} as any,
      extension: {},
      isCustom: "",
      constant: constant,
      customMessageType: CUSTOM_MESSAGE_TYPE,
      index: 0,
      userType: store.state?.userInfo?.user_type,
      token: $cookies?.get(_cookie.TOKEN) || "",
      inviteExpired: false,
      inviteStatus: 0,
      inviteLoading: false,
      jobApplyStatus: null,
      jobInfo: {},
      imId: "",
    });

    const host = computed(() => {
      const host_config = siteInfo.value.host_config;
      if (host_config) {
        const config = JSON.parse(host_config);
        const envConfig = props.isH5 ? config?.mobile : config?.pc;
        return {
          job_host: envConfig?.job_host || process.env.VUE_APP_HOST,
          b_host: envConfig?.b_host || process.env.VUE_APP_B_HOST,
          c_host: envConfig?.c_host || process.env.VUE_APP_C_HOST,
        };
      }
      return {
        job_host: process.env.VUE_APP_HOST,
        b_host: process.env.VUE_APP_B_HOST,
        c_host: process.env.VUE_APP_C_HOST,
      };
    });

    onMounted(async () => {
      data.data = props.data;
      data.imId = props.imId;
      const {
        message: { payload },
      } = props.data;
      data.isCustom = payload.data || " ";
      data.isCustom = JSONToObject(payload.data);
      if (payload.data === constant.typeService) {
        data.extension = JSONToObject(payload.extension);
      }
      // 生成随机背景色企业logo
      const companyId = (data.isCustom as any)?.company_id;
      if (companyId && isNumber(+companyId)) {
        let num = +companyId;
        if (+companyId > Number.MAX_SAFE_INTEGER) {
          num = +companyId.substring(companyId.length - 6);
        }
        data.index = +num % 6;
      }
    });
    watchEffect(async () => {
      const businessID = (data.isCustom as any)?.businessID;
      switch (businessID) {
        case CUSTOM_MESSAGE_TYPE.INVITE_APPLY: {
          // 邀投递卡片状态查询
          const inviteId = (data.isCustom as any)?.invite_id;
          // 取缓存
          const inviteCard = localStorage.getItem(`IM-Invite-Card-${inviteId}`);
          if (inviteCard) {
            const { is_expired, status } = JSON.parse(inviteCard);
            data.inviteExpired = is_expired;
            data.inviteStatus = status;
          } else {
            data.inviteLoading = true;
            const res =
              data.userType === UserType.PERSONAL
                ? await inviteApplyListViaApp([inviteId], data.token)
                : await inviteBApplyListViaApp([inviteId], data.token);
            if (res?.code === API_RESULT_CODE.SUCCESS) {
              const { is_expired, status } = res.data?.list?.[0];
              // 已过期/已同意/已拒绝的状态存缓存
              (is_expired || status === 1 || status === 2) &&
                localStorage.setItem(`IM-Invite-Card-${inviteId}`, JSON.stringify({ is_expired, status }));
              data.inviteExpired = is_expired;
              data.inviteStatus = status;
            }
            data.inviteLoading = false;
          }
          break;
        }
        case CUSTOM_MESSAGE_TYPE.JOB: {
          const conversationType = data.data.message?.conversationType;
          const from = data.data.message?.from;
          let userType = "";
          let userId = "";
          const obj = await ProfileServer.getUserProfile([data.imId]);
          const chatUserList = obj.data;
          if (chatUserList?.[0]) {
            const { profileCustomField } = chatUserList[0];
            const profileCustom = profileCustomField.filter((item: any) => item.key === "Tag_Profile_Custom_Info")?.[0]
              ?.value;
            const profileCustomObj = JSONToObject(profileCustom);
            userType = profileCustomObj.user_type;
            userId = profileCustomObj.user_id;
          }
          // 私聊且双方都不是企业用户或者群聊且我是个人用户、并且当前消息的sender是我自己才有状态
          if (
            (conversationType === "C2C" && (userType === UserType.PERSONAL || data.userType === UserType.PERSONAL)) ||
            (conversationType === "GROUP" && from === store.state?.userInfo?.im_id)
          ) {
            // 职位卡片状态查询
            const jobId = (data.isCustom as any)?.job_id;
            const resJob = await jobApplyStatusViaApp(jobId, userId, data.token);
            if (resJob?.code === API_RESULT_CODE.SUCCESS) {
              const { applied } = resJob.data;
              data.jobApplyStatus = applied;
              data.jobInfo = resJob.data;
            }
          }
          break;
        }
      }
    });
    const openLink = (url: any) => {
      window.open(url);
    };
    const handleCallMessageIcon = () => {
      const callType = JSON.parse(JSON.parse(data?.data?.message?.payload?.data)?.data)?.call_type;
      let className = "";
      switch (callType) {
        case 1:
          className = "icon-call-voice";
          break;
        case 2:
          className = "icon-call-video";
          break;
        default:
          break;
      }
      return className;
    };

    const handleCallAgain = async () => {
      const callType = JSON.parse(JSON.parse(props?.data?.message?.payload?.data)?.data)?.call_type;
      switch (data?.data?.message?.conversationType) {
        case (window as any).TUIKitTUICore.TIM.TYPES.CONV_C2C:
          // eslint-disable-next-line no-case-declarations
          const { flow, to, from } = data?.data?.message;
          if (to === from) break;
          try {
            await (window as any)?.TUIKitTUICore?.TUIServer?.TUICallKit?.call({
              userID: flow === "out" ? to : from,
              type: callType,
            });
            (window as any)?.TUIKitTUICore?.isOfficial && VuexStore?.commit && VuexStore?.commit("handleTask", 6);
          } catch (error) {
            console.warn(error);
          }
          break;
        case (window as any).TUIKitTUICore.TIM.TYPES.CONV_GROUP:
          break;
        default:
          break;
      }
    };
    // 职位详情页
    const toJobDetail = () => {
      window.open(`${host.value.job_host}/job/detail?id=${(data.isCustom as any)?.job_id}`);
    };
    // 单位主页
    const toCompanyDetail = () => {
      window.open(`${host.value.job_host}/company?id=${(data.isCustom as any)?.company_id}`);
    };
    // 双选会详情页
    const toSxhDetail = () => {
      window.open(
        (data.isCustom as any)?.url ||
          `${process.env.VUE_APP_SXH_HOST}/detail/introduction?interchoiceId=${(data.isCustom as any)?.id}`
      );
    };
    // 简历详情页
    const toResumeDetail = () => {
      const isCustom: any = data.isCustom;
      if (isCustom) {
        const resume_preview_url_to_b = `${host.value.b_host}/resume/preview?id=${isCustom.apply_id}`;
        const resume_preview_url_to_c = `${host.value.c_host}/resume-applied?apply_id=${isCustom.apply_id}`;
        const share_resume_preview_url = `${host.value.c_host}/resume-preview?resume_id=${isCustom.share_id}&usertype=anyone`;
        if (isCustom.apply_id) {
          window.open(data.userType === UserType.COMPANY ? resume_preview_url_to_b : resume_preview_url_to_c);
        } else {
          window.open(share_resume_preview_url);
        }
      }
    };
    // 职位卡片点击查看简历
    const toJobApplyDetail = () => {
      window.open(
        data.userType === UserType.COMPANY
          ? (data.jobInfo as any)?.resume_preview_url_to_b.replace(process.env.VUE_APP_B_HOST, host.value.b_host)
          : (data.jobInfo as any)?.resume_preview_url_to_c.replace(process.env.VUE_APP_C_HOST, host.value.c_host)
      );
    };
    // 拒绝投递邀请
    const handleRejectInvite = async () => {
      const res = await rejectInviteApply((data.isCustom as any).invite_id);
      if (res?.code === API_RESULT_CODE.SUCCESS) {
        data.inviteStatus = 2;
        const systemMessage = {
          data: {
            businessID: CUSTOM_MESSAGE_TYPE.SYSTEM_TIP,
            company_show_msg: `对方已拒绝${(data.isCustom as any).job_name}投递邀请`,
            personal_show_msg: "已拒绝投递邀请",
          },
          description: "已拒绝投递邀请",
        };
        TUIServer.sendCustomMessage(systemMessage).catch((error: any) => {
          ElMessage.error(error);
        });
      }
    };
    // 同意投递邀请
    const handleInvite = () => {
      const { job_id, invite_id } = data.isCustom as any;
      if (job_id) {
        window.open(
          `${host.value.job_host}/job/detail?id=${job_id}&im_id=${data.data.message.from || ""}&invite_id=${
            invite_id || ""
          }&auto_apply=true`
        );
      } else {
        ElMessage.warning("邀请卡片格式不正确");
      }
    };
    // 动态详情页
    const toCommunityDetail = () => {
      const shareUrl = (data.isCustom as any)?.shareUrl;
      shareUrl && window.open(shareUrl);
    };
    // 话题分享
    const toTopicDetail = () => {
      window.open(`${host.value.job_host}/topic/trend?id=${(data.isCustom as any)?.topicId}`);
    };
    return {
      ...toRefs(data),
      host,
      isUrl,
      openLink,
      handleCallMessageIcon,
      handleCallAgain,
      toJobDetail,
      toCompanyDetail,
      toSxhDetail,
      toResumeDetail,
      toJobApplyDetail,
      handleRejectInvite,
      handleInvite,
      toCommunityDetail,
      toTopicDetail,
    };
  },
});
