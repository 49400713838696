export const emojiUrl = "https://image.iguopin.com/app/emoji/";
export const emojiMap: any = {
  "[GP-求应聘]": "qiuyingpin.png",
  "[GP-求面试]": "qiumianshi.png",
  "[GP-求offer]": "qiuoffer.png",
  "[GP-打call]": "dacall.png",
  "[GP-奋斗]": "fendou.png",
  "[GP-鼓掌]": "guzhang.png",
  "[GP-献花]": "xianhua.png",
  "[GP-嘿吼]": "heihou.png",
  "[GP-抠鼻]": "koubi.png",
  "[GP-微笑]": "weixiao.png",
  "[GP-大笑]": "daxiao.png",
  "[GP-喜欢]": "xihuan.png",
  "[GP-皱眉]": "zhoumei.png",
  "[GP-酷]": "ku.png",
  "[GP-流泪]": "liulei.png",
  "[GP-害羞]": "haixiu.png",
  "[GP-闭嘴]": "bizui.png",
  "[GP-困]": "kun.png",
  "[GP-愉快]": "yukuai.png",
  "[GP-调皮]": "tiaopi.png",
  "[GP-生气]": "shengqi.png",
  "[GP-难过]": "nanguo.png",
  "[GP-发怒]": "fanu.png",
  "[GP-咒骂]": "zhouma.png",
  "[GP-捂脸笑]": "wulianxiao.png",
  "[GP-笑哭]": "xiaoku.png",
  "[GP-晕]": "yun.png",
  "[GP-点赞]": "dianzan.png",
  "[GP-批评]": "piping.png",
  "[GP-握手]": "woshou.png",
  "[GP-耶]": "ye.png",
  "[GP-OK]": "ok.png",
  "[GP-爱心]": "aixin.png",
  "[GP-心碎]": "xinsui.png",
  "[GP-吻]": "wen.png",
  "[GP-骷髅]": "kulou.png",
  "[GP-尴尬]": "ganga.png",
  "[GP-疑问]": "yiwen.png",
  "[GP-冷汗]": "lenghan.png",
  "[GP-白眼]": "baiyan.png",
  "[GP-叹气]": "tanqi.png",
  "[GP-鄙视]": "bishi.png",
  "[GP-打脸]": "dalian.png",
  "[GP-欢迎]": "huanying.png",
  "[GP-吃惊]": "chijing.png",
  "[GP-期待]": "qidai.png",
  "[GP-得意]": "deyi.png",
  "[GP-亲亲]": "qinqin.png",
  "[GP-比心]": "bixin.png",
  "[GP-钱迷]": "qianmi.png",
  "[GP-懵]": "meng.png",
  "[GP-捂脸哭]": "wulianku.png",
  "[GP-大哭]": "daku.png",
  "[GP-无语]": "wuyu.png",
  "[GP-无奈]": "wunai.png",
  "[GP-饥饿]": "jie.png",
  "[GP-哇塞]": "wasai.png",
  "[GP-吐]": "tu.png",
  "[GP-生病]": "shengbing.png",
  "[GP-中毒]": "zhongdu.png",
};
export const emojiName: Array<string> = [
  "[GP-求应聘]",
  "[GP-求面试]",
  "[GP-求offer]",
  "[GP-打call]",
  "[GP-奋斗]",
  "[GP-鼓掌]",
  "[GP-献花]",
  "[GP-嘿吼]",
  "[GP-抠鼻]",
  "[GP-微笑]",
  "[GP-大笑]",
  "[GP-喜欢]",
  "[GP-皱眉]",
  "[GP-酷]",
  "[GP-流泪]",
  "[GP-害羞]",
  "[GP-闭嘴]",
  "[GP-困]",
  "[GP-愉快]",
  "[GP-调皮]",
  "[GP-生气]",
  "[GP-难过]",
  "[GP-发怒]",
  "[GP-咒骂]",
  "[GP-捂脸笑]",
  "[GP-笑哭]",
  "[GP-晕]",
  "[GP-点赞]",
  "[GP-批评]",
  "[GP-握手]",
  "[GP-耶]",
  "[GP-OK]",
  "[GP-爱心]",
  "[GP-心碎]",
  "[GP-吻]",
  "[GP-骷髅]",
  "[GP-尴尬]",
  "[GP-疑问]",
  "[GP-冷汗]",
  "[GP-白眼]",
  "[GP-叹气]",
  "[GP-鄙视]",
  "[GP-打脸]",
  "[GP-欢迎]",
  "[GP-吃惊]",
  "[GP-期待]",
  "[GP-得意]",
  "[GP-亲亲]",
  "[GP-比心]",
  "[GP-钱迷]",
  "[GP-懵]",
  "[GP-捂脸哭]",
  "[GP-大哭]",
  "[GP-无语]",
  "[GP-无奈]",
  "[GP-饥饿]",
  "[GP-哇塞]",
  "[GP-吐]",
  "[GP-生病]",
  "[GP-中毒]",
];
export const faceUrl = "https://web.sdk.qcloud.com/im/assets/face-elem/";

export const bigEmojiList: Array<any> = [
  {
    icon: 1,
    list: [
      "yz00",
      "yz01",
      "yz02",
      "yz03",
      "yz04",
      "yz05",
      "yz06",
      "yz07",
      "yz08",
      "yz09",
      "yz10",
      "yz11",
      "yz12",
      "yz13",
      "yz14",
      "yz15",
      "yz16",
      "yz17",
    ],
  },
  {
    icon: 2,
    list: [
      "ys00",
      "ys01",
      "ys02",
      "ys03",
      "ys04",
      "ys05",
      "ys06",
      "ys07",
      "ys08",
      "ys09",
      "ys10",
      "ys11",
      "ys12",
      "ys13",
      "ys14",
      "ys15",
    ],
  },
  {
    icon: 3,
    list: [
      "gcs00",
      "gcs01",
      "gcs02",
      "gcs03",
      "gcs04",
      "gcs05",
      "gcs06",
      "gcs07",
      "gcs08",
      "gcs09",
      "gcs10",
      "gcs11",
      "gcs12",
      "gcs13",
      "gcs14",
      "gcs15",
      "gcs16",
    ],
  },
];
