
import { defineComponent, reactive, watchEffect, toRefs, watch, ref, onMounted, inject, computed } from "vue";
import { useI18nLocale } from "../../../../../TUIPlugin/TUIi18n";
import { onClickOutside } from "@vueuse/core";
import { getResumeListViaApp, shareResumeViaApp } from "@/api/resume";
import { API_RESULT_CODE, CUSTOM_MESSAGE_TYPE } from "@/config/types/common";
import { ElMessage } from "element-plus";
import { _cookie } from "@/utils/constant";
import { GreetingData } from "@/config/types/user";
import { useStore } from "vuex";
import { VueCookies } from "vue-cookies";

const SendResume = defineComponent({
  type: "custom",
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    isMute: {
      type: Boolean,
      default: () => false,
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
    autoApply: {
      type: Boolean,
      default: () => false,
    },
    jobId: {
      type: String,
      default: "",
    },
    imId: {
      type: String,
      default: "",
    },
  },
  setup(props: any) {
    const store = useStore && useStore();
    const siteInfo = computed(() => store.state.siteInfo);
    const $cookies = inject<VueCookies>("$cookies");
    const data = reactive({
      list: [],
      isMute: false,
      locale: useI18nLocale(),
      resumeVisible: false,
      activeName: "online",
      resumeId: "",
      resumeList: [],
      resumeDefault: false,
      lastResumeId: "",
      token: "",
      btnDisable: false,
      loading: false,
      autoApply: false,
      jobId: "",
      imId: "",
    });

    const dialog: any = ref();

    const job_host = computed(() => {
      const host_config = siteInfo.value.host_config;
      if (host_config) {
        const config = JSON.parse(host_config);
        const envConfig = props.isH5 ? config?.mobile : config?.pc;
        return envConfig?.job_host || process.env.VUE_APP_HOST;
      }
      return process.env.VUE_APP_HOST;
    });

    watchEffect(() => {
      data.resumeVisible = props.show;
      data.isMute = props.isMute;
      data.autoApply = props.autoApply;
      data.jobId = props.jobId;
      data.imId = props.imId;
    });

    watch(
      () => data.resumeVisible,
      () => {
        if (data.resumeVisible) {
          data.loading = true;
          const token = $cookies?.get(_cookie.TOKEN);
          if (token) {
            data.token = token;
            getResumeListViaApp(token).then((res: any) => {
              if (res?.code === API_RESULT_CODE.SUCCESS) {
                const { list = [] } = res.data;
                const checkedResume = list.find((li: any) => li.default_share);
                if (checkedResume) {
                  data.resumeId = checkedResume.id;
                  data.lastResumeId = checkedResume.id;
                }
                data.resumeList = list;
              } else {
                ElMessage.error(res?.msg || "简历获取失败");
              }
              data.loading = false;
            });
          }
        }
      }
    );

    const openSendResumeModal = () => {
      data.autoApply
        ? window.open(`${job_host.value}/job/detail?id=${data.jobId}&im_id=${data.imId}&auto_apply=true`)
        : (data.resumeVisible = true);
    };

    const toggleShow = () => {
      data.resumeVisible = !data.resumeVisible;
    };

    onClickOutside(dialog, () => {
      data.resumeVisible = false;
    });

    const handleSend = async () => {
      if (!data.resumeId) {
        ElMessage.warning("请选择发出的简历");
      } else {
        data.btnDisable = true;
        const res = await shareResumeViaApp(
          data.resumeId,
          data.resumeDefault || data.resumeId === data.lastResumeId,
          data.token
        );
        if (res?.code === API_RESULT_CODE.SUCCESS) {
          toggleShow();
          const {
            share_id,
            education_merge_text,
            user_merge_text,
            work_exp_merge_text,
            share_resume_preview_url,
            resume_id,
            full_name,
          } = res.data;
          const resumeMessage = {
            data: {
              businessID: CUSTOM_MESSAGE_TYPE.RESUME,
              share_id,
              education_merge_text,
              user_merge_text,
              work_exp_merge_text,
              share_resume_preview_url,
              resume_id,
              full_name,
            },
            description: `${full_name}的简历`,
          };
          await SendResume.TUIServer.sendCustomMessage(resumeMessage)
            .then(async () => {
              const greetings: GreetingData[] = store.state.userInfo.greetings.filter(
                (item: any) => item.greeting_type === "resume_share"
              );
              const greeting = greetings?.[0]?.content || "你好，这里是我的简历，欢迎随时沟通";
              const greetingMessage = {
                data: {
                  businessID: CUSTOM_MESSAGE_TYPE.GREETING,
                  company_show_msg: greeting,
                  personal_show_msg: greeting,
                },
                description: greeting,
              };
              await SendResume.TUIServer.sendCustomMessage(greetingMessage).catch((error: any) => {
                ElMessage.error(error);
              });
            })
            .catch((error: any) => {
              ElMessage.error(error);
            });
        } else {
          ElMessage.error(res?.msg || "发送失败");
        }
        data.btnDisable = false;
      }
    };
    return {
      ...toRefs(data),
      openSendResumeModal,
      toggleShow,
      dialog,
      handleSend,
    };
  },
});
export default SendResume;
