import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-226105d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "risk-warning-wrap" }
const _hoisted_2 = { class: "risk-warning" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.msg) + " ", 1),
      _createElementVNode("a", {
        class: "warning-link",
        href: _ctx.url,
        target: "_blank"
      }, _toDisplayString(_ctx.title), 9, _hoisted_3)
    ])
  ]))
}