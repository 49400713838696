
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  setup() {
    const store = useStore && useStore();
    const data = reactive({
      msg: "",
      title: "",
      url: "",
    });

    onMounted(() => {
      const warn_info = store.state?.userInfo?.warn_info;
      if (warn_info) {
        data.msg =
          warn_info.msg ||
          "求职过程中，国聘平台不会收取任何费用，如遇用人方收费要求，请提高警惕，保护好自身与财产安全，谨防上当受骗！若发现虚假、违法违规招聘信息或广告，请拨打举报电话：010-88006655。";
        data.title = warn_info.title || "点击了解防骗指南";
        data.url = warn_info.url || "https://mp.weixin.qq.com/s/JmlzwPfYdwmUrrQ3VEhN4A";
      }
    });

    return {
      ...toRefs(data),
    };
  },
});
